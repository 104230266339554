import React, { Component } from "react";
import "antd/dist/antd.css";
import { Row, Input, Form, Button, Col, Icon, notification } from "antd";
import _ from "lodash";
import "./Organization.css";

import { editOrganization } from "../../util/Shufu";
// import UploadImageForm from "../UploadImageForm/UploadImageForm";
import UploadAvatarForm from "../UploadAvatarForm/UploadAvatarForm";
import { isNull } from "util";

const FormItem = Form.Item;
const { TextArea } = Input;

class _OrganizationForm extends Component {
  constructor(props) {
    super(props);
    console.log("constructor props", props);
    this.originalFormValues = props
    this.state = {
      // originalFormValues: props,
      formData:{informWay:[]}
    };
    this._handleSubmit = this._handleSubmit.bind(this);
  }


  async componentDidMount() {
    this.props.form.setFieldsValue({
      id: this.props.id,
      name: this.props.name,
      phone: this.props.phone,
      address: this.props.address,
      website: this.props.website,
      description: this.props.description,
      // informWay: this.props.forms,
      avatar: this.props.avatar
    });
    const {formData} = this.state
    for(var item of this.props.forms){
      if(item){
        formData.informWay.push(item)
      }
    }
    this.setState({
      formData
    })
    console.log("didMount formData:",this.state.formData)
  }

  addFormData = index => {
    const { formData } = this.state;
    let da = { id: "", tags: [] };
    formData.informWay.push(da);
    this.setState({
      formData: formData
    });
  };

  delFormData = index => {
    const { formData } = this.state;
    formData.informWay.splice(index, 1);
    this.setState({ formData: formData });
  };

  removeConfigBtn(index, size) {
    if (size > 0) {
      return (
        <button
          style={{
            width: "25px",
            height: "25px",
            marginTop: "6px",
            padding: "0px"
          }}
          className="btn btn-small btn-info"
          onClick={() => this.delFormData(index)}
        >
          -
        </button>
      );
    }
  }

  _handleSubmit() {
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        console.log("Received values of form: ", values);
        let organizationField = [
          "id",
          "name",
          "phone",
          "address",
          "website",
          //   "forms",
          // "avatar",
          "description"
        ];
        let body = {
          forms: this.originalFormValues.forms,
          avatar: this.state.avatar
        };
        let changed = false;
        organizationField.forEach(key => {
          if (this.originalFormValues[key] !== values[key]) {
            body[key] = values[key];
            changed = true;
          } else {
            body[key] = this.originalFormValues[key];
          }
        });
        if (!isNull(body.avatar)) {
          changed = true;
        }

        this.state.formData &&
        this.state.formData.informWay.map((element, index) => {
          element.id = values["id"+index]
          element.tags = []
          if(values["tags"+index]){
            const str = values["tags"+index].toString().split(',')
            for(let tag in str){
              element.tags.push(str[tag])
            }
          }
        });
        if(this.originalFormValues.forms!==this.state.formData.informWay){
          changed = true
          body.forms = this.state.formData.informWay
        }
        console.log('handle body:',body)
        // console.log("valueChanged: ", changed);
        if (changed) {
          try {
            await editOrganization(body);
            // alert("添加成功");
            notification.open({
              icon: <Icon type="smile" style={{ color: "#108ee9" }} />,
              message: "success",
              description: "修改成功"
            });
          } catch (e) {
            console.log(e);
            // alert("添加失败");
            notification.open({
              icon: <Icon type="frown" style={{ color: "red" }} />,
              message: "fail",
              description: "修改失败"
            });
          }
        } else {
          notification.open({ message: "未作任何修改，关闭请按右上角按钮" });
        }
        this.setState({
          loading: false
        });
      } else {
        this.setState({
          loading: false
        });
      }
    });
  }


  render() {
    const { getFieldDecorator } = this.props.form;
    const { formData } = this.state;
    const data = formData.informWay;
    const formItemLayout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 }
    };

    const descriptionLayout = {
      labelCol: { span: 3 },
      wrapperCol: { span: 19 }
    };

    const formLayout = {
      labelCol: {span: 10},
      wrapperCol: {span: 12}
    }

    return (
      <div className="section">
        <Form key={this.props.id}>
          {/* <Row type="flex" justify="space-between" >  */}
          <Row >
            <UploadAvatarForm
              onUploadFinish={result => {
                this.setState({
                  uploadValues: result,
                  avatar: result.url
                });
              }}
            />
          </Row>

          <Row>
            <Col span={10}>
              <FormItem {...formItemLayout} label="企业ID">
                {getFieldDecorator("id", {
                  rules: [{ required: false, message: "企业ID" }]
                })(<Input disabled={true} />)}
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem {...formItemLayout} label="企业名称">
                {getFieldDecorator("name", {
                  rules: [{ required: true, message: "企业名称" }]
                })(<Input />)}
              </FormItem>
            </Col>
          </Row>
          <Row>
            {/* <Col span={7}>
              <FormItem {...formItemLayout} label="forms">
                {getFieldDecorator("forms", {
                  rules: [{ required: false, message: "" }]
                })(<Input />)}
              </FormItem>
            </Col> */}
            <Col span={10}>
              <FormItem {...formItemLayout} label="联系方式">
                {getFieldDecorator("phone", {
                  rules: [
                    { required: true, message: "请输入企业联系方式" },
                    {
                      pattern: /^((\d{7,8})|(1\d{10}))$/,
                      message: "请输入1开头的11位手机号"
                    }
                  ]
                })(<Input />)}
              </FormItem>
            </Col>

            <Col span={12}>
              <FormItem {...formItemLayout} label="网址">
                {getFieldDecorator("website", {
                  rules: [{ required: false, message: "企业网址" }]
                })(<Input />)}
              </FormItem>
            </Col>
          </Row>

          <FormItem {...descriptionLayout} label="地址">
            {getFieldDecorator("address", {
              rules: [{ required: false, message: "企业地址" }]
            })(<Input />)}
          </FormItem>

          <FormItem label="描述" {...descriptionLayout}>
            {getFieldDecorator("description", {
              rules: [{ required: false, message: "企业相关描述" }]
            })(<TextArea rows={5} />)}
          </FormItem>

          <div className="control-group">
            {data.map((element, index) => {
              return (
                <div key={index}>
                  <Row >
                    <Col span={7}>
                      <FormItem {...formLayout}  label="量表ID">
                        {getFieldDecorator(`id${index}`, {
                          initialValue: element.id,
                          rules: [
                            { required: true, message: "Please input formId" }
                          ]
                        })(<Input />)}
                      </FormItem>
                    </Col>
                    <Col span={11}>
                      <FormItem {...formItemLayout} label="量表标签">
                        {getFieldDecorator(`tags${index}`, {
                          initialValue: element.tags
                        })(<Input key={element.tags} />)}
                      </FormItem>
                    </Col>
                    <span className="btn-group btn-small">
                      <button
                        style={{
                          width: "25px",
                          height: "25px",
                          marginTop: "6px",
                          marginLeft:"30px",
                          marginRight:"6px",
                          padding: "0px"
                        }}
                        className="btn btn-primary"
                        onClick={() => this.addFormData(index)}
                      >
                        +
                      </button>
                      {this.removeConfigBtn(index, formData.informWay.length)}
                    </span>
                  </Row>
                </div>
              );
            })}
          </div>

          <Button
            style={{ marginLeft: "43%",width:"100px" }}
            type="primary"
            // size={"large"}
            onClick={() => {
              this._handleSubmit();
            }}
          >
            确定
          </Button>
        </Form>
      </div>
    );
  }
}

const OrganizationForm = Form.create()(_OrganizationForm);
export default OrganizationForm;
