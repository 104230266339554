import React, { Component } from "react";
// import './App.css';
import "antd/dist/antd.css";
import {
  Input,
  Form,
  Icon,
  Button,
  Select,
  Modal,
  notification,
  Upload,
  message
} from "antd";

import { getUploadImageUrl } from "../../util/Shufu";
import Cookies from "js-cookie";

const Option = Select.Option;
const FormItem = Form.Item;
const { TextArea } = Input;
const Dragger = Upload.Dragger;

class UploadImageForm extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  render() {
    let siuvoSessionToken = Cookies.get("SIUVO_SUPPORT_SESSION");
    const props = {
      name: this.props.name || "image",
      multiple: false,
      action: getUploadImageUrl(),
      headers: { Authorization: siuvoSessionToken },
      onChange: info => {
        const status = info.file.status;
        if (status !== "uploading") {
          console.log(info.file, info.fileList);
        }
        if (status === "done") {
          message.success(`${info.file.name} file uploaded successfully.`);
          console.log(info.file);

          if (this.props.onUploadFinish) {
            this.props.onUploadFinish(info.file.response);
          }
        } else if (status === "error") {
          message.error(`${info.file.name} file upload failed.`);
        }
      }
    };
    return (
      <div>
        <Dragger {...props}>
          <p className="ant-upload-drag-icon">
            <Icon type="inbox" />
          </p>
          <p className="ant-upload-text">点击此处上传文件</p>
          {/*<p className="ant-upload-hint">Support for a single or bulk upload. Strictly prohibit from uploading company data or other band files</p>*/}
        </Dragger>
      </div>
    );
  }
}

//
// const WebContentForm = Form.create()(_WebContentForm);
//
export default UploadImageForm;
