import React from 'react';
import 'antd/dist/antd.css';
import './BatchUploadWebContent.css'
import {checkWebContent, createWebContentAndUploadImageToAliyun, uploadImage} from '../../util/Shufu';

import {Row, Col, Button, Form, Icon, message, Spin, Upload, List, Modal, Tag,Tooltip,Progress} from 'antd';
import axios from 'axios';

import * as XLSX from 'xlsx';

import webContentErrorExampleImage from '../../image/web-content-error.png'
import Cookies from "js-cookie";

const confirm = Modal.confirm;


class BatchUploadWebContent extends React.Component {

    state = {
        loading: false,
        webContentList: [],
        pageViewed:{},
        isUploading:false,
        uploadResultList:[],
        showIsUploadingModal:false,
        currentUploadCount:0,
        currentUploadInfo:""
    };

    componentDidMount() {
        // console.log(this.props)

    }
    _isEveryPageViewed(){
        let pageViewed = this.state.pageViewed
        return Object.keys(pageViewed).map(page=> pageViewed[page]).every(viewed=>viewed)
    }

    _getTipTitle(){
        let result = "请依次查看每一页的内容，确认无误后再上传。  "
        if(!this._isEveryPageViewed()){
            result += `还没有检查第${Object.keys(this.state.pageViewed).filter(page=>!this.state.pageViewed[page]).join(", ")}页`
        }
        return result
    }

    onExcelLoaded(uploadEvent) {
        let file = uploadEvent.file
        console.log("onExcelLoaded", file)
        const reader = new FileReader();
        reader.onload = () => {
            var fileData = reader.result;
            var wb = XLSX.read(fileData, {type: 'binary'});
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            const _webContentList = XLSX.utils.sheet_to_json(ws, {blankrows: false});
            /* Update state */
            console.log(_webContentList)
            let webContentList = _webContentList.map(item => {
                return {
                    "url": item.url || "",
                    "title": item.title || "",
                    "description": item.description || item.Description || "",
                    "imageUrl": item.imageUrl || "",
                    "mediaType": item.mediaType || "",
                    "urlType": item.urlType || "",
                    "topicList": (item.topicList || item.topics || "").split(",").filter(topic => topic !== '').map(topic => topic.trim()) || [],
                    "isFeatured": item.isFeatured || item['isFeatured (true/false)'] || false,
                    "_checked": undefined
                }
            })
            console.log(webContentList);
            this.setState({
                webContentList
            })


            let pageViewed = {};
            for(let i = 0; i<Math.ceil(webContentList.length/10);i++){
                pageViewed[i+1] = i===0
            }
            this.setState({
                pageViewed
            })
        };
        reader.readAsBinaryString(file);


        return


    }



    async _uploadSingleWebContent(index){
        let webContent = this.state.webContentList[index];
        this.setState({
            currentUploadInfo:`正在检查 ${webContent.title}`
        })
        await checkWebContent(webContent.url);

        this.setState({
            currentUploadInfo:`正在上传 ${webContent.title} 的内容`
        })
        await createWebContentAndUploadImageToAliyun(webContent)




    }

    async batchUpload(){
        console.log("batchUpload")
        this.setState({
            isUploading:true,
            showIsUploadingModal:true,
            uploadResultList:[],
            currentUploadCount:0,
        },async ()=>{
            console.log("START batchUpload 2")
            for(let i = 0; i< this.state.webContentList.length;i++){
            // for(let i = 0; i< 2;i++){
                try{
                    this.setState({
                        currentUploadCount:i
                    })
                    await this._uploadSingleWebContent(i)
                    let uploadResultList = this.state.uploadResultList;
                    uploadResultList.push({success:true, info:`${i}. 上传${this.state.webContentList[i].title}成功。`})
                    this.setState({
                        uploadResultList
                    })
                }
                catch(e){
                    let uploadResultList = this.state.uploadResultList;
                    uploadResultList.push({success:false,info:`${i}. 上传${this.state.webContentList[i].title}失败。${e}`})
                    this.setState({
                        uploadResultList
                    })

                }
            }
            this.setState({
                currentUploadInfo:`完成`,
                isUploading:false
            })


        })



    }

    renderUploadProcessModal(){
        return (
            <Modal
                title={this.state.isUploading?"正在上传，请不要关闭窗口":"完成"}
                centered
                visible={this.state.showIsUploadingModal}
                closable={!this.state.isUploading}
                maskClosable={false}
                footer={[]}
                onCancel={() => {
                    this.setState({showIsUploadingModal: false})
                }}
                width={'70%'}
            >
                <div style={{overflow: 'auto', height: '40vh', backgroundColor:"#f0f0f0",padding:10}}>
                    {this.state.uploadResultList.map((result)=>{
                        return result.success?(<div><Tag color="green">{result.info}</Tag></div>):(<div><Tag color="red">{result.info}</Tag></div>)
                    })
                    }

                </div>
                <div>
                    <Progress percent={((this.state.currentUploadCount+1)/this.state.webContentList.length)*100} status="active"  showInfo={false}/>
                    当前进度 <Tag color="lime">{this.state.currentUploadCount+1}/{this.state.webContentList.length}</Tag>
                </div>
                <div>
                    {this.state.currentUploadInfo}
                </div>
            </Modal>
        )
    }

    confirmUpload() {
        confirm({
            title: `批量上传前请再三确认内容正确无误！`,
            content: (<div>
                <div>
                    请确认<span style={{fontWeight: "900"}}>每一页</span>的内容，
                    确保每一页上没有出现如下图中所示的<span style={{fontWeight: "900", color: "red", fontSize: "150%"}}>红色指示错误</span>

                    <div>如果有错误，请先在你的Excel文件里修改，然后点击【重新选择EXCEL文件】重新上传。</div>

                    <img style={{
                        width: "100%",
                        marginTop: 10,
                        borderColor: "black",
                        borderWidth: 2,
                        borderRadius: 5,
                        borderStyle: "dashed"
                    }} src={webContentErrorExampleImage}/>

                    <div style={{ color: "red"}}>如果你给我们的数据库喂食奇怪的数据，TA会生病的，然后我们的App就会一脸懵逼了。( ´థ౪థ）σ</div>
                </div>
            </div>),
            onOk: () => this.batchUpload(),
            onCancel() {
            },
            cancelText: "我再检查检查",
            okText: "上传！",
            centered:true,

            width: "55%"
        });
    }


    renderWebContent(item) {

        return (
            <List.Item
                key={item.title}
                // actions={[<IconText type="star-o" text="156" />, <IconText type="like-o" text="156" />, <IconText type="message" text="2" />]}
                // extra={<img width={272} alt="logo" src="https://gw.alipayobjects.com/zos/rmsportal/mqaQswcyDLcXyDKnZfES.png" />}
                extra={item.imageUrl ? (
                    <img height={100} style={{backgroundColor: "red", minHeight: 150, color:'white'}} alt="⚠️题图炸裂…　看看是不是链接写错了？"
                         src={item.imageUrl}/>) : null}
            >
                <List.Item.Meta
                    // avatar={<Avatar src={item.avatar} />}
                    title={item.title ? (<a href={item.href}>{item.title} {item.isFeatured ? (
                        <span style={{
                            backgroundColor: "rgba(0, 0, 0, 0.85)",
                            color: "white",
                            padding: 3,
                            borderRadius: 2
                        }}><Icon
                            type="like" theme="filled"/>首页推荐</span>) : null}</a>) : (
                        <span style={{color: 'red', fontWeight: "900", fontSize: "300%"}}><Icon type="warning"
                                                                                                theme="filled"/>标题缺失！！</span>)}
                    description={item.description ? item.description : (
                        <span style={{color: 'red', fontWeight: "900", fontSize: "300%"}}><Icon type="warning"
                                                                                                theme="filled"/>描述缺失！！</span>)}
                />
                {/*{item.description}*/}
                <div>
                    <span>话题：</span>{item.topicList.map(topic => {
                    return (topic.indexOf("，") > -1 ? (
                        <span style={{
                            padding: 5,
                            borderRadius: 5,
                            backgroundColor: "#c0c0c0",
                            marginLeft: 10
                        }}>{topic} <span
                            style={{color: 'red', fontWeight: "900", fontSize: "200%", display: 'inline-block'}}><Icon
                            type="warning" theme="filled"/>标签中含有中文逗号！！</span></span>
                    ) : (<span style={{
                        padding: 5,
                        borderRadius: 5,
                        backgroundColor: "#c0c0c0",
                        marginLeft: 10
                    }}>{topic}</span>))
                })}
                </div>

                <Row type="flex" justify="flex-start" style={{marginTop: 10}}>
                    <Col span={6} className='title-text'>
                        <div style={{fontSize: 15, color: "#a0a0a0"}}>
                            类型：
                            {item.mediaType === 'video' ? (
                                <span><Icon type="video-camera" theme="outlined"/> 视频</span>) : null}
                            {item.mediaType === 'text' ? (
                                <span><Icon type="file-text" theme="outlined"/> 文章</span>) : null}
                            {item.mediaType !== 'video' && item.mediaType !== 'text' ? (
                                <span style={{fontSize: "300%", color: "red", fontWeight: "900"}}><Icon
                                    type="warning" theme="filled"/>没有填写类型或未知的类型</span>) : null}
                        </div>
                    </Col>
                    {
                        item.mediaType === 'video' ? (
                            <Col span={6} className='title-text'>
                                <div style={{fontSize: 15, color: "#a0a0a0"}}>
                                    链接类型：
                                    {item.urlType === 'embed' ? (
                                        <span>第三方播放器嵌入</span>) : null}
                                    {item.urlType === 'link' ? (
                                        <span>SHUFU自有视频</span>) : null}
                                    {item.urlType !== 'link' && item.urlType !== 'embed' ? (
                                        <span style={{fontSize: "300%", color: "red", fontWeight: "900"}}><Icon
                                            type="warning" theme="filled"/>没有填写视频链接类型或未知的视频链接类型</span>) : null}

                                </div>
                            </Col>
                        ) : null
                    }


                </Row>
            </List.Item>
        )


    }


    renderWebContentList() {
        return (<List
            itemLayout="vertical"
            size="small"
            pagination={{
                onChange: (page) => {
                    console.log(page);
                    window.scrollTo(0, 0)
                    let pageViewed = this.state.pageViewed;
                    pageViewed[page] = true;
                    this.setState({
                        pageViewed
                    })
                },
                pageSize: 10,
                onShowSizeChange:(current, size)=>{
                    console.log("onShowSizeChange",current, size)
                }
            }}
            dataSource={this.state.webContentList}
            // footer={<div><b>ant design</b> footer part</div>}
            renderItem={this.renderWebContent.bind(this)}
        />)
    }

    render() {
        if (this.state.webContentList.length) {
            return (<div style={{margin: 20, padding: 20, backgroundColor: "white", borderRadius: 5}}>
                {this.renderWebContentList()}
                <Row type="flex" justify="space-around" style={{marginTop: 10}}>
                    <Col span={6} className='title-text'>
                        <Button type="dashed" block size={'large'} onClick={() => {
                            this.setState({
                                webContentList: []
                            })
                        }}>重新选择EXCEL文件</Button>
                    </Col>
                    <Col span={12} className='title-text'>
                        <div className='upload-button-wrapper'>
                            <Tooltip placement="topLeft"
                                     title={this._getTipTitle()}
                                     arrowPointAtCenter={true}
                            >
                                <Button
                                    // className={"submit-button"}
                                    type="primary"
                                    block
                                    size={'large'}
                                    onClick={() => {
                                        this.confirmUpload()
                                    }}
                                    style={{width:"100%"}}
                                    disabled={!this._isEveryPageViewed()}
                                >上传！</Button>
                            </Tooltip>
                        </div>


                    </Col>
                </Row>
                {this.renderUploadProcessModal()}
            </div>)
        }
        else {
            const props = {
                action: '/',
                // onChange: this.handleChange,
                multiple: false,
                customRequest: this.onExcelLoaded.bind(this)
            };
            return (
                <div style={{padding: 30, margin: "auto", backgroundColor: "white", borderRadius: 5}}>

                    <div style={{padding: 30, margin: 'auto', textAlign: "center"}}>
                        <Upload {...props} fileList={this.state.fileList} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
                            <Button type="primary" size={'large'}>
                                <Icon type="upload"/> 点击选择EXCEL文件
                            </Button>
                        </Upload>

                    </div>

                    <div style={{padding: 10, margin: 'auto', textAlign: "center"}}>
                        Excel文件的后缀名一定要是 <Tag color="red">.xlsx</Tag> 哟！

                    </div>

                    <div style={{padding: 10, margin: 'auto', textAlign: "center"}}>
                        请确保Excel文件中，第一行表头有如下字段：

                    </div>
                    <div>
                        <Tag color="green">url</Tag>
                        <Tag color="green">title</Tag>
                        <Tag color="green">description</Tag>
                        <Tag color="green">imageUrl</Tag>
                        <Tag color="green">mediaType</Tag>
                        <Tag color="green">urlType</Tag>
                        <Tag color="green">topics</Tag>
                        <Tag color="green">isFeatured</Tag>
                    </div>
                </div>)
        };
    }
}

export default BatchUploadWebContent
