import React, { Component } from "react";
// import './App.css';
import "antd/dist/antd.css";

import "./ServiceItem.css";
import { Row, Col, Table, Input } from "antd";
import moment from "moment";

import { getMoney, getMoneyHistory } from "../../util/Shufu";
import { message } from "antd/lib/index";
import ServiceItemForm from "../../component/ServiceItemForm/ServiceItemForm";
import queryString from "query-string";

const Search = Input.Search;

export default class ServiceItem extends Component {
  state = {
    serviceItemId: ""
  };

  onSearch(serviceItemId) {
    let params = queryString.parse(this.props.location.search || "");
    params.serviceItemId = serviceItemId;
    let qString = queryString.stringify(params);
    let path = this.props.history.location.pathname;
    //
    // console.log(this.props.history)
    // console.log(path+"?"+qString)

    this.props.history.push(path + "?" + qString);
    this.setState({
      serviceItemId
    });
    return;

    console.log(this.props.location);
    if (window.history.pushState) {
      var newurl =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname +
        "?" +
        queryString.stringify(params);
      window.history.pushState({ path: newurl }, "", newurl);
    }
  }
  componentDidMount() {
    let params = queryString.parse(this.props.location.search);
    console.log(params);
    window.scrollTo(0, 0);
    if (params.serviceItemId) {
      this.setState({
        serviceItemId: params.serviceItemId
      });
    }
  }

  render() {
    return (
      <div className="contianer">
        <Row>
          <Col span={18}>
            <Search
              placeholder="Service Item Id"
              onSearch={value => this.onSearch(value)}
              enterButton
            />
          </Col>
        </Row>

        <Row>
          <ServiceItemForm serviceItemId={this.state.serviceItemId} />
        </Row>
      </div>
    );
  }
}
