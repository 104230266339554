import React, { Component } from "react";
// import './App.css';
import "antd/dist/antd.css";
import {
  Row,
  Col,
  Input,
  Form,
  Icon,
  Button,
  Select,
  Modal,
  notification
} from "antd";
import UploadImageForm from "../../component/UploadImageForm/UploadImageForm";

const Option = Select.Option;
const FormItem = Form.Item;
const { TextArea } = Input;
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0
    },
    sm: {
      span: 14,
      offset: 4
    }
  }
};

class _WebContentForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      WebContent: props.WebContent,
      video: props.WebContent && props.WebContent.mediaType === "video",
      uploadImageModalVisible: false,
      imagePreviewUrl: props.WebContent.imageUrl
    };
    this._handleSubmit = this._handleSubmit.bind(this);
  }

  componentDidMount() {
    const WebContent = this.state.WebContent;
    this.props.form.setFieldsValue({
      title: WebContent.title,
      description: WebContent.description,
      mediaType: WebContent.mediaType,
      imageUrl: WebContent.imageUrl,
      url: WebContent.url,
      urlType: WebContent.urlType
    });
  }

  getWebContentValue() {
    this.setState({
      modal1Visible: false
    });
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        let WebContent = JSON.parse(JSON.stringify(this.state.WebContent));
        Object.keys(values).forEach(key => {
          WebContent[key] = values[key];
        });

        if (WebContent.mediaType !== "video") {
          delete WebContent.urlType;
        }
        console.log("after editing", WebContent);
        this.props.finishForm(WebContent);
      } else {
        this.openBadNotifaction("你在干什么!填完再交不然要坏掉的!!!!!!");
      }
    });
  }

  renderLinkEditor() {
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 }
    };
    return (
      <div>
        <div hidden={!this.state.video}>
          <FormItem {...formItemLayout} label="链接">
            {getFieldDecorator("url", {
              rules: [{ required: true, message: "请输入视频链接" }]
            })(
              <Input
                prefix={
                  <Icon type="link" style={{ color: "rgba(0,0,0,.25)" }} />
                }
              />
            )}
          </FormItem>
          <FormItem {...formItemLayout} label="链接类型">
            {getFieldDecorator("urlType", {
              rules: [{ required: this.state.video, message: "请输入视频链接" }]
            })(
              <Select style={{ width: 120 }}>
                <Option value="embed">embed</Option>
                <Option value="link">link</Option>
              </Select>
            )}
          </FormItem>
        </div>
        <div hidden={this.state.video}>
          <FormItem {...formItemLayout} label="链接">
            {getFieldDecorator("url", {
              rules: [{ required: true, message: "请输入文章链接" }]
            })(
              <Input
                prefix={
                  <Icon type="link" style={{ color: "rgba(0,0,0,.25)" }} />
                }
              />
            )}
          </FormItem>
        </div>
      </div>
    );
  }

  renderWebContent() {
    if (this.state.WebContent === null) {
      return null;
    }
    const WebContent = this.state.WebContent;
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 }
    };
    return (
      <Form key={WebContent.id}>
        <FormItem {...formItemLayout} label="标题">
          {getFieldDecorator("title", {
            rules: [{ required: true, message: "请输入标题" }]
          })(<Input placeholder={WebContent.title} />)}
        </FormItem>
        <FormItem {...formItemLayout} label={"描述"}>
          {getFieldDecorator("description", {
            rules: [{ required: true, message: "请输入描述" }]
          })(
            <TextArea
              rows={5}
              prefix={
                <Icon type="phone" style={{ color: "rgba(0,0,0,.25)" }} />
              }
              placeholder={WebContent.description}
            />
          )}
        </FormItem>
        <FormItem {...formItemLayout} label="图片">
          <Row type="flex" justify="space-between">
            <Col span={20}>
              {getFieldDecorator("imageUrl", {
                rules: [{ required: false, message: "请输入图片地址" }]
              })(<Input />)}
            </Col>
            <Col span={2}>
              <Button
                type="primary"
                shape="circle"
                icon="upload"
                onClick={() => {
                  this.setState({ uploadImageModalVisible: true });
                }}
              />
            </Col>
          </Row>

          <div>
            <img style={{ maxHeight: 200 }} src={this.state.imagePreviewUrl} />
          </div>
        </FormItem>
        <FormItem {...formItemLayout} label="类型">
          {getFieldDecorator("mediaType", {
            rules: [{ required: true, message: "Please input nickname" }]
          })(
            <Select
              style={{ width: 120 }}
              onChange={props => {
                this.setState({ video: props === "video" }, () => {
                  this.props.form.validateFields(["urlType"], { force: true });
                });
              }}
            >
              <Option value="video">视频</Option>
              <Option value="text">文章</Option>
            </Select>
          )}
        </FormItem>
        {this.renderLinkEditor()}
        <FormItem {...tailFormItemLayout}>
          <Button
            type="primary"
            onClick={() => this.setState({ modal1Visible: true })}
            className="login-form-button"
          >
            {this.props.WebContent.id ? "修改文本" : "创建文本"}
          </Button>
        </FormItem>
      </Form>
    );
  }

  _handleSubmit() {
    this.setState({
      modal1Visible: false
    });
  }

  openBadNotifaction(description) {
    notification.open({
      icon: <Icon type="frown" style={{ color: "red" }} />,
      message: "失败",
      description: description
    });
  }

  renderModal() {
    return (
      <Modal
        title="修改文本"
        style={{ top: 20 }}
        visible={this.state.modal1Visible}
        onOk={this.getWebContentValue.bind(this)}
        onCancel={() => this.setState({ modal1Visible: false })}
      >
        <p>钥匙只有一把 改坏form了找都找不回来的!!!!!</p>
      </Modal>
    );
  }

  renderUploadImageModal() {
    return (
      <Modal
        title="上传图片"
        // style={{top: 20}}
        visible={this.state.uploadImageModalVisible}
        // onOk={this.handleOk}
        // onCancel={this.handleCancel}
        // width={'80%'}
        footer={[]}
        onCancel={() => {
          this.setState({ uploadImageModalVisible: false });
        }}
      >
        <UploadImageForm
          onUploadFinish={result => {
            this.props.form.setFieldsValue({
              imageUrl: result.url
            });
            this.setState({
              imagePreviewUrl: `${result.url}/small`,
              uploadImageModalVisible: false
            });
          }}
        />
      </Modal>
    );
  }

  render() {
    return (
      <div>
        {this.renderWebContent()}
        {this.renderModal()}
        {this.renderUploadImageModal()}
      </div>
    );
  }
}

const WebContentForm = Form.create()(_WebContentForm);

export default WebContentForm;
