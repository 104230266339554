export const SERVER_INFO = [{
  name: 'prod',
  url: 'https://api.siuvo.cn/'
}, {
  name: 'sit',
  url: 'https://sit.siuvo.cn/'
}, {
  name: 'demo',
  url: 'http://demo.siuvo.cn/'
}, {
  name: 'smhc',
  url: 'https://smhc.siuvo.cn:7075/'
}, {
  name: 'smhc_uat',
  url: 'https://smhc.siuvo.cn:7076/'
}, {
  name: 'uat_cn',
  url: 'https://server.uat.siuvo.cn/'
},
{
  name: 'srrsh',
  url: 'https://srrsh.siuvo.cn:9443/'
}]