import axios from 'axios';
import Cookies from 'js-cookie';

import { Route, HashRouter, Switch, Link } from 'react-router-dom';
// const API_BASE_URL = "http://sit.shufu.siuvo.com/";

function getBaseUrl() {
  return (
    (JSON.parse(localStorage.getItem('adminServer')) || {}).key ||
    'https://sit.siuvo.cn/'
  );
}

export function getUploadImageUrl() {
  return getBaseUrl() + 'juliet/support/upload/image';
}

async function _request(options) {
  // let _token = cookie.load('appAuthToken')

  options = {
    apiName: 'romeo',
    ...options
  };
  // let url =
  //     API_BASE_URL +
  //     (options.apiName === 'romeo' ? 'romeo/api/1.2/rest' : options.apiName) +
  //     options.url;

  let url = '';
  let data;
  let method = options.method;

  if (options.apiName === 'romeo') {
    url = getBaseUrl() + 'juliet' + '/support/java-proxy';
    data = {
      url: options.url,
      method: options.method,
      body: options.data
    };
    method = 'POST';
  } else {
    url = getBaseUrl() + options.apiName + options.url;
    data = options.data;
  }
  let siuvoSessionToken = Cookies.get('SIUVO_SUPPORT_SESSION');

  let _options = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },

    //withCredentials: true,

    ...options,
    url,
    data,
    method
    // withCredentials: true
  };

  if (siuvoSessionToken) {
    _options.headers['Authorization'] = siuvoSessionToken;
  }

  try {
    let res = await axios.request(_options);
    if (res.status === 200 || res.status === 201) {
      return res.data;
    } else {
      throw `${res.data.errorMessage}: ${res.data.errorCode}`;
    }
  } catch (error) {
    if (error.response && error.response.status === 401) {
      // not login
      Cookies.set('SIUVO_SUPPORT_SESSION', '');
      window.location = '/';
    }
    if (
      error.response &&
      error.response.data &&
      error.response.data.data &&
      error.response.data.data.error &&
      error.response.data.data.error.errorMessage
    ) {
      throw new Error(error.response.data.data.error.errorMessage);
    }
    if (error.response && error.response.data && error.response.data.error) {
      throw new Error(
        error.response.data.error.errorMessage || error.response.data.error
      );
    } else {
      throw error;
    }
  }
}

export async function getMoney(options) {
  const url = `/support/payment/getMoney`;
  return await _request({
    method: 'POST',
    url,
    apiName: 'juliet',
    data: {
      moneyId: options.moneyId
    }
    //   'Access-Control-Request-Headers':'authorization,content-type'
  });
}

export async function getMoneyHistory(options) {
  const url = `/support/payment/getHistory?offset=${options.offset ||
    0}&limit=${options.limit || 10}`;
  return await _request({
    method: 'POST',
    url,
    apiName: 'juliet',
    data: {
      moneyId: options.moneyId
    }
    //   'Access-Control-Request-Headers':'authorization,content-type'
  });
}

export async function login(options) {
  const url = `/support/login`;
  let { username, password } = options;
  return await _request({
    method: 'POST',
    url,
    apiName: 'juliet',
    data: {
      username,
      password
    }
    //   'Access-Control-Request-Headers':'authorization,content-type'
  });
}

export async function javaLogin(options) {
  const url = `/user/login`;
  let { username, password } = options;
  return await _request({
    method: 'POST',
    url,
    apiName: 'romeo',
    data: {
      username,
      password
    }
    //   'Access-Control-Request-Headers':'authorization,content-type'
  });
}

export async function getUser(id) {
  const url = `/user/profile/by-id/${id}`;
  return await _request({
    method: 'GET',
    url
    //   'Access-Control-Request-Headers':'authorization,content-type'
  });
}

export async function deleteUserComplete(id) {
  const url = `/user/${id}/complete`;
  return await _request({
    method: 'DELETE',
    url
    //   'Access-Control-Request-Headers':'authorization,content-type'
  });
}

export async function editUser(body) {
  const url = `/user/profile`;
  return await _request({
    method: 'POST',
    url,
    data: {
      ...body
    }
  });
}

//提交创建用户界面的表单数据
export async function addUser(body) {
  const url = '/user/signup';
  return await _request({
    method: 'POST',
    url,
    data: {
      ...body
    }
  });
}

//添加企业信息
export async function createOrganization(body) {
  const url = '/user/organization';
  return await _request({
    method: 'PUT',
    url,
    data: {
      ...body
    }
  });
}

//编辑修改企业信息
export async function editOrganization(body) {
  const id = body.id;
  const url = `/user/organization/${id}`;
  return await _request({
    method: 'POST',
    url,
    data: {
      ...body
    }
  });
}

//查询企业信息
export async function getOrganization() {
  const url = '/user/organization/all';
  return await _request({
    method: 'GET',
    url
  });
}

// //查询企业信息
// export async function searchOrganization(options) {
//   let { offset = 0, limit = 10, keyword } = options;
//   const url = `/user/organization/searchOrganization?offset=${offset}&limit=${limit}&keyword=${keyword}`;
//   return await _request({
//     method: 'POST',
//     url,
//     apiName: 'juliet'
//   });
// }

// //查询单个企业信息
// export async function getOrganizationNameById(id){
//   const url = `/user/organization/${id}`;
//   return await _request({
//     method: 'GET',
//     url
//   });
// }

export async function getAllWebContent() {
  const url = '/webContent/all';
  return await _request({
    method: 'GET',
    url
  });
}

export async function getWebContentById(id) {
  const url = `/webContent/${id}`;
  return await _request({
    method: 'GET',
    url
  });
}

export async function updateWebContentById(webContent) {
  const url = '/webContent/update';
  return await _request({
    method: 'POST',
    url,
    data: {
      ...webContent
    }
  });
}

export async function getServiceItemById(serviceItemId) {
  const url = `/service/item/by-id/${serviceItemId}`;
  return await _request({
    method: 'GET',
    url
  });
}

export async function getProductsByServiceItemId(serviceItemId) {
  const url = `/support/product/getProductsByServiceItemId`;
  // let {username,password} = options
  return await _request({
    method: 'POST',
    url,
    apiName: 'juliet',
    data: {
      serviceItemId
    }
  });
}

export async function getServiceItemByProviderId(providerId) {
  const url = `/service/item/by-provider/${providerId}`;
  return await _request({
    method: 'GET',
    url
  });
}

export async function createWebContent(webContent) {
  const url = '/webContent/create';
  return await _request({
    method: 'PUT',
    url,
    data: {
      ...webContent
    }
  });
}

export async function deleteWebContentById(id) {
  const url = `/webContent/${id}`;
  return await _request({
    method: 'DELETE',
    url
  });
}

export async function getProduct(productId) {
  const url = `/support/product/getProduct`;
  // let {username,password} = options
  return await _request({
    method: 'POST',
    url,
    apiName: 'juliet',
    data: {
      productId
    }
  });
}

export async function editProduct(product) {
  const url = `/support/product/editProduct`;
  // let {username,password} = options
  return await _request({
    method: 'POST',
    url,
    apiName: 'juliet',
    data: {
      ...product
    }
  });
}

export async function addProduct(product) {
  const url = `/support/product/addProduct`;
  // let {username,password} = options
  return await _request({
    method: 'POST',
    url,
    apiName: 'juliet',
    data: {
      ...product
    }
  });
}

export async function updateServiceItem(serviceItem) {
  const url = '/service/item';
  return await _request({
    method: 'POST',
    url,
    data: {
      ...serviceItem
    }
  });
}

export async function checkWebContent(webContentUrl) {
  const url = `/support/content/check`;
  return await _request({
    method: 'POST',
    url,
    apiName: 'juliet',
    data: {
      url: webContentUrl
    }
  });
}

export async function uploadImage(bodyFormData) {
  const url = `/support/upload/image`;
  return await _request({
    method: 'POST',
    url,
    apiName: 'juliet',
    data: bodyFormData,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data'
    }
  });
}

export async function createWebContentAndUploadImageToAliyun(webContent) {
  const url = '/support/content/add';
  return await _request({
    method: 'POST',
    url,
    apiName: 'juliet',
    data: webContent
  });
}

export async function getServiceItemsByUserId(userId) {
  const url = `/service/item/by-provider/${userId}`;
  return await _request({
    method: 'GET',
    url
  });
}

export async function createServiceItem(serviceItem) {
  const url = '/service/item';
  return await _request({
    method: 'PUT',
    url,
    data: {
      ...serviceItem
    }
  });
}

export async function getServiceInstanceWordCloud() {
  const url = '/support/wordcloud/serviceInstance';
  return await _request({
    method: 'GET',
    url,
    apiName: 'juliet'
  });
}

export async function getNewServiceInstanceStatistics(options) {
  const url = '/support/chuxin/newServiceInstanceStatistics';
  return await _request({
    method: 'POST',
    url,
    apiName: 'juliet',
    data: options
  });
}

export async function getLastPeriodDailyOrderMoney(options) {
  const url = '/support/chuxin/getLastPeriodDailyOrderMoney';
  return await _request({
    method: 'POST',
    url,
    apiName: 'juliet',
    data: options
  });
}

export async function getLastPeriodDailyFormCount(options) {
  const url = '/support/chuxin/getLastPeriodDailyFormCount';
  return await _request({
    method: 'POST',
    url,
    apiName: 'juliet',
    data: options
  });
}

export async function getLastPeriodDailyNewCDSCount(options) {
  const url = '/support/chuxin/getLastPeriodDailyNewCDSCount';
  return await _request({
    method: 'POST',
    url,
    apiName: 'juliet',
    data: options
  });
}

export async function getKPI(options) {
  let {
    company,
    dateFrom = new Date().getTime() - 7 * 3600 * 1000,
    dateTo = new Date().getTime()
  } = options;
  const url = `/statistics/getKPI/${company}?dateFrom=${dateFrom}&dateTo=${dateTo}`;
  return await _request({
    method: 'GET',
    url
  });
}

export async function getLatestQrCode(options) {
  let { offset = 0, limit = 10 } = options;
  const url = `/support/qrCode/getLatestQrCode?offset=${offset}&limit=${limit}`;
  return await _request({
    method: 'POST',
    url,
    apiName: 'juliet'
  });
}

export async function searchQrCode(options) {
  let { offset = 0, limit = 10, keyword } = options;
  const url = `/support/qrCode/searchQrCode?offset=${offset}&limit=${limit}&keyword=${keyword}`;
  return await _request({
    method: 'POST',
    url,
    apiName: 'juliet'
  });
}

export async function createQrCode(options) {
  const url = `/service/qrCodeData`;
  return await _request({
    method: 'PUT',
    url,
    data: options
  });
}

export async function getLatestWechatQrCode(options) {
  let { offset = 0, limit = 10 } = options;
  const url = `/support/qrCode/getLatestWechatQrCode?offset=${offset}&limit=${limit}`;
  return await _request({
    method: 'POST',
    url,
    apiName: 'juliet'
  });
}

export async function searchUser(options) {
  let { offset = 0, limit = 10, keyword, userType = 'provider' } = options;
  const url = `/support/user/searchUser?offset=${offset}&limit=${limit}&keyword=${keyword}`;
  return await _request({
    method: 'POST',
    url,
    apiName: 'juliet'
  });
}

export async function createWechatQr(sceneData) {
  const url = `/user/wechatQr`;
  return await _request({
    method: 'PUT',
    url,
    data: {
      sceneData
    }
  });
}

export async function formActionLogs(data) {
  const { startTime, endTime, serviceItemId, formId } = data;
  const url = `/KPI/avgRespTime/formActionLogs?startTime=${startTime}&endTime=${endTime}&serviceItemId=${serviceItemId}&formId=${formId}`;
  return await _request({
    method: 'GET',
    url,
    apiName: 'romeo'
  });
}

export async function serviceInstance(data) {
  const { startTime, endTime, serviceItemId } = data;
  const url = `/KPI/avgRespTime/serviceInstance?startTime=${startTime}&endTime=${endTime}&serviceItemId=${serviceItemId}`;
  return await _request({
    method: 'GET',
    url,
    apiName: 'romeo'
  });
}

export async function userTodo(data) {
  const { startTime, endTime, serviceItemId, formId } = data;
  const url = `/KPI/avgRespTime/userTodo?startTime=${startTime}&endTime=${endTime}&serviceItemId=${serviceItemId}&name=${formId}`;
  return await _request({
    method: 'GET',
    url,
    apiName: 'romeo'
  });
}