import React, { Component } from 'react';
// import './App.css';
import 'antd/dist/antd.css';

import { Layout, Menu, Breadcrumb, Icon } from 'antd';



const { Header, Content, Footer, Sider } = Layout;
const SubMenu = Menu.SubMenu;

export  class Page2 extends React.Component {
    state = {
        collapsed: false,
    };

    onCollapse = (collapsed) => {
        console.log(collapsed);
        this.setState({ collapsed });
    }

    render() {
        return (

          <div> PAGE 2</div>
        );
    }
}

