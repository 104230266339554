import React, { Component } from 'react';
import './QrCode.css';
import 'antd/dist/antd.css';

import { Layout, Menu, List, Icon, Row,Col, Input,Card,Button,Modal } from 'antd';
import {getLatestQrCode, searchQrCode, createQrCode} from '../../util/Shufu';
import QrCodeForm from '../../component/QrCodeForm/QrCodeForm';


import moment from 'moment'
import {message} from "antd/lib/index";

const { Header, Content, Footer, Sider } = Layout;
const SubMenu = Menu.SubMenu;
const Search = Input.Search


var QRCode = require('qrcode.react');



export default class QrCode extends React.Component {
    constructor(props){
        super(props);
        this.state={
            qrCodeDataList:[],
            total:1,
            pageSize:12,
            resultType:"latest",
            searchKeyword:"",
            createQrCodeModalVisible:false,
            editQrCodeModalVisible:false,
            viewQrCode:{}
        }
    }

    async componentDidMount(){
        await this._getLatestQrCodes({offset:0, limit:this.state.pageSize})
    }


    async _getLatestQrCodes(options){
        let {offset, limit} = options
        let result = await getLatestQrCode({offset, limit});
        let {total, data} = result;
        this.setState({
            qrCodeDataList:data,
            total,
            resultType:"latest"
        })
    }

    async _searchQrCodes(options){
        let {offset, limit, keyword} = options
        let result = await searchQrCode({offset, limit, keyword});
        let {total, data} = result;
        this.setState({
            qrCodeDataList:data,
            total,
            resultType:"search"
        })
    }

    renderCreateQrCodeModal() {
        return (
            <div>
                <Modal
                    title={'新建二维码'}
                    style={{top: 20}}
                    visible={this.state.createQrCodeModalVisible}
                    // onOk={this.handleOk}
                    // onCancel={this.handleCancel}
                    width={'80%'}
                    footer={[]}
                    onCancel={() => {
                        this.setState({createQrCodeModalVisible: false})
                    }}

                >
                    <QrCodeForm onSubmit={(data)=>{
                        this._createNewQrCode(data)
                    }} />

                </Modal>
            </div>
        );
    };

    async _createNewQrCode(data){
        console.log('111111', data)
        try{
            let result = await createQrCode(data)
            message.success('创建二维码成功')
        }
        catch(e){
            message.error(e.toString());

        }
        this.setState({
            createQrCodeModalVisible:false
        })
        this._getLatestQrCodes({
            offset:0,
            limit:this.state.pageSize
        })
    }

    renderEditQrCodeModal(){
        return (
            <div>
                <Modal
                    title={`查看二维码 - ${this.state.viewQrCode.title||''}`}
                    style={{top: 20}}
                    visible={this.state.editQrCodeModalVisible}
                    // onOk={this.handleOk}
                    // onCancel={this.handleCancel}
                    width={'80%'}
                    footer={[]}
                    onCancel={() => {
                        this.setState({editQrCodeModalVisible: false})
                    }}
                >
                    <Row>
                        <Col span={10}>
                            <div style={{fontSize:"150%", textAlign:"center", marginBottom:40}}>
                                {this.state.viewQrCode.title || '生成二维码'}
                            </div>
                            <div style={{textAlign:"center"}}>
                                <QRCode  size={300} value={`http://www.siuvo.com.cn/download?data={"qrCodeId":"${this.state.viewQrCode.id}"}`} />
                            </div>


                        </Col>
                        <Col span={14}>
                            <QrCodeForm id={this.state.viewQrCode.id} title={this.state.viewQrCode.title} data={this.state.viewQrCode.data}/>
                        </Col>


                    </Row>


                </Modal>
            </div>
        );
    }



    render(){
        return(<div>
            <div className='contianer'>
                <Row>

                    <Col span={20}>
                        <Search
                            placeholder="输入关键词"
                            onSearch={value => {
                                this.setState({
                                    searchKeyword:value
                                });
                                if(value!==''){
                                    this._searchQrCodes({
                                        offset:0,
                                        limit:this.state.pageSize,
                                        keyword:value

                                    })
                                }else{
                                    this._getLatestQrCodes({
                                        offset:0,
                                        limit:this.state.pageSize,
                                    })
                                }

                            }}
                            enterButton
                        />
                    </Col>

                    <Col span={4}>
                        <div style={{textAlign:"right"}}>
                            <Button type="primary" icon="plus-circle" onClick={()=>{
                                this.setState({createQrCodeModalVisible: true})
                            }} >新建二维码</Button>
                        </div>

                    </Col>

                </Row>
                <Row>
                    <Col span={24}>
                        <div style={{marginTop:15}}>
                            <List
                                grid={{ gutter: 16, column: 4 }}
                                dataSource={this.state.qrCodeDataList}
                                pagination={{
                                    onChange: (page) => {
                                        console.log(page);
                                        if(this.state.resultType==='latest'){
                                            this._getLatestQrCodes({
                                                offset:(page-1) * this.state.pageSize,
                                                limit: this.state.pageSize
                                            })
                                        }
                                        else if (this.state.resultType==='search'){
                                            this._getLatestQrCodes({
                                                offset:(page-1) * this.state.pageSize,
                                                limit: this.state.pageSize,
                                                keyword:this.state.searchKeyword
                                            })
                                        }

                                    },
                                    pageSize: this.state.pageSize,
                                    total: this.state.total
                                }}
                                renderItem={item => (
                                    <List.Item>

                                        <Card title={item.title || '--'} actions={[<Icon type="setting" onClick={() => {
                                            console.log(item)
                                            this.setState({
                                                editQrCodeModalVisible: true,
                                                viewQrCode: item
                                            })
                                        }}/>]}
                                        >
                                            <div style={{textAlign:"center"}}>
                                                <div>
                                                    {moment(item.createdTime).format("YYYY-MM-DD")}
                                                </div>
                                                <QRCode value={`http://www.siuvo.com.cn/download?data={"qrCodeId":"${item.id}}"`} />
                                            </div>
                                            </Card>
                                    </List.Item>
                                )}
                            />
                        </div>

                    </Col>

                </Row>
            </div>

            {this.renderCreateQrCodeModal()}
            {this.renderEditQrCodeModal()}
        </div>)
    }






}

