import React, { Component } from "react";
// import './App.css';
import "antd/dist/antd.css";
import WebContentForm from "../../component/WebContentForm/WebContentForm";

import "./EditWebContent.css";
import { Form, Icon, notification } from "antd";

import { getWebContentById, updateWebContentById } from "../../util/Shufu";
import { message } from "antd/lib/index";

class EditWebContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      WebContent: {}
    };
  }

  componentDidMount() {
    const id = this.props.match.params.id;
    if (id) {
      this._getWebContentById(id);
    }
  }

  async _getWebContentById(id) {
    try {
      let result = await getWebContentById(id);
      let WebContent = result.javaApiResult;
      this.setState({
        WebContent
      });
    } catch (e) {
      message.error(e.toString());
    }
  }

  async _updateWebContentById(content) {
    try {
      await updateWebContentById(content);
      this.openNotification("修改成功");
    } catch (e) {
      message.error(e.toString());
    }
  }

  openNotification(description) {
    notification.open({
      icon: <Icon type="smile" style={{ color: "#108ee9" }} />,
      message: "成功",
      description: description
    });
  }

  render() {
    return (
      <div className="contianer">
        <div className="section">
          <WebContentForm
            key={this.state.WebContent.id || "new"}
            finishForm={this._updateWebContentById.bind(this)}
            WebContent={this.state.WebContent}
          />
        </div>
      </div>
    );
  }
}

export default EditWebContent;
