import React, { Component } from "react";
// import './App.css';
import "antd/dist/antd.css";
import WebContentForm from "../../component/WebContentForm/WebContentForm";

import { Form, Icon, notification } from "antd";

import { createWebContent } from "../../util/Shufu";
import { message } from "antd/lib/index";

class CreateWebContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      WebContent: {}
    };
  }

  async _createWebContent(content) {
    content.isFeatured = false;
    try {
      await createWebContent(content);
      this.openNotification("修改成功");
    } catch (e) {
      message.error(e.toString());
    }
  }

  openNotification(description) {
    notification.open({
      icon: <Icon type="smile" style={{ color: "#108ee9" }} />,
      message: "成功",
      description: description
    });
  }

  render() {
    return (
      <div className="contianer">
        <div className="section">
          <WebContentForm
            key={this.state.WebContent.id || "new"}
            finishForm={this._createWebContent.bind(this)}
            WebContent={this.state.WebContent}
          />
        </div>
      </div>
    );
  }
}

export default CreateWebContent;
