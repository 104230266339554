import React, { Component } from "react";
// import './App.css';
import "antd/dist/antd.css";

import {
  Row,
  Col,
  Table,
  Input,
  Form,
  Icon,
  Select,
  Switch,
  List,
  Card,
  Button,
  Modal,
  Spin
} from "antd";
import ProductFrom from "../ProductForm/ProductForm";

import {
  getServiceItemById,
  getProductsByServiceItemId,
  editProduct,
  addProduct,
  updateServiceItem,
  getOrganization
} from "../../util/Shufu";
import { message } from "antd/lib/index";
import _ from "lodash";
// import {Form} from "antd/lib/index";
const { TextArea } = Input;

const Search = Input.Search;
const FormItem = Form.Item;
const Option = Select.Option;

class _ServiceItemForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      serviceItemId: props.serviceItemId,
      serviceItem: {},
      products: [],
      addProductModalVisiable: false,
      selectedProductId: "",
      productSubmitLoading: false,
      serviceItemLoading: true,
      // OrganizationIds : [],
      // OrganizationNames : []
      orgInfor: []
    };
  }

  async componentDidMount() {
    await this._getOrganization();
  }

  async componentWillReceiveProps(newProps) {
    this.setState({
      serviceItemId: newProps.serviceItemId
    });

    if (newProps.serviceItemId !== this.props.serviceItemId) {
      await this._getServiceItemById(newProps.serviceItemId);
      await this._getProductListByServiceItem(newProps.serviceItemId);
    }
  }

  jsonValidatorThrottled = _.debounce(function(rule, value, callback) {
    try {
      JSON.parse(value);
      callback();
    } catch (e) {
      callback("错误的JSON格式: " + e.toString());
    }
  }, 1000);

  async _getServiceItemById(serviceItemId) {
    this.setState({ serviceItemLoading: true });

    try {
      let result = await getServiceItemById(serviceItemId);
      console.log("_getServiceItemById", result);
      let serviceItem = result.javaApiResult;

      this.props.form.setFieldsValue({
        serviceItemId: serviceItem.id,
        name: serviceItem.name,
        description: serviceItem.description,
        greetings_en: serviceItem.greetings_en,
        greetings_zh: serviceItem.greetings_zh,
        numberOfCustomers: serviceItem.numberOfCustomers,
        numberOfProviders: serviceItem.numberOfProviders,
        promptForRestart: serviceItem.promptForRestart,
        ensureUnique: serviceItem.ensureUnique,
        muteCustomer: serviceItem.muteCustomer,
        processKey: serviceItem.serverParam.processKey,
        wfVariables: JSON.stringify(
          JSON.parse(
            (serviceItem.variables && serviceItem.variables.wfVariables) || "{}"
          ),
          null,
          2
        )
      });
    } catch (e) {
      message.error(e.toString());
    }
    this.setState({ serviceItemLoading: false });
  }

  async _getProductListByServiceItem(serviceItemId) {
    try {
      let result = await getProductsByServiceItemId(serviceItemId);
      let products = result.products;
      this.setState({
        products
      });
      console.log("product", result);
    } catch (e) {
      message.error(e.toString());
    }
  }

  async _updateSerivceItem(serviceItem) {
    this.setState({
      serviceItemLoading: true
    });
    try {
      await updateServiceItem(serviceItem);
      if (this.props.serviceItemId) {
        await this._getServiceItemById(this.props.serviceItemId);
      }
    } catch (e) {
      message.error(e.toString());
    }
    this.setState({
      serviceItemLoading: false
    });
  }

  //获取企业名称和对应的ID
  async _getOrganization() {
    try {
      let result = await getOrganization();
      console.log("result类型: " + typeof result);

      let orgInfor = result.javaApiResult;
      console.log("orgInfor类型: ", typeof orgInfor);
      console.log("organization information: ", orgInfor);
      this.setState({
        orgInfor
      });
      //企业名称和ID分别存入数组中+
      // let OrganizationNames = [];
      // let OrganizationIds = [];
      // result.forEach(element => {
      //     options[] = element.id;
      //     OrganizationIds.push(element.id);
      //     OrganizationNames.push(element.name);
      // });
    } catch (e) {
      message.error(e.toString());
    }
  }

  async _handleSubmit() {
    this.setState({
      serviceItemLoading: true
    });
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        console.log("Received values of form: ", values);

        let serviceItem = {
          id: values.serviceItemId ? values.serviceItemId : undefined,
          name: values.name,
          organizationIds: values,
          description: values.description,
          numberOfProviders: values.numberOfProviders,
          numberOfCustomers: values.numberOfCustomers,
          // "serviceType": "ITEM",
          serverParam: {
            processKey: values.processKey,
            variables: {
              wfVariables: JSON.stringify(JSON.parse(values.wfVariables))
            }
          },
          variables: {
            wfVariables: JSON.stringify(JSON.parse(values.wfVariables))
          },
          greetings_en: values.greetings_en || "",
          greetings_zh: values.greetings_zh || "",
          //"workGroups": [],
          //"topicList": [],
          //"itemType": "individualTherapy",

          muteCustomer: values.muteCustomer,
          ensureUnique: values.ensureUnique,
          promptForRestart: values.promptForRestart
        };

        this.setState({ serviceItemLoading: false });

        console.log("serviceItem", serviceItem);
        console.log("this.state.orgInfor: ", this.state.orgInfor);

        if (serviceItem.id) {
          await this._updateSerivceItem(serviceItem);
        } else {
        }
      } else {
        this.setState({ serviceItemLoading: false });
      }
    });
  }

  async onProductSubmit(product) {
    console.log("onProductSubmit", product);

    this.setState({
      productSubmitLoading: true
    });
    // if has productId, then it's edit procut
    if (product.productId) {
      try {
        let result = await editProduct(product);
        await this._getProductListByServiceItem(this.state.serviceItemId);
        this.setState({
          selectedProductId: "",
          addProductModalVisiable: false
        });
      } catch (e) {
        message.error(e.toString());
      }
      this.setState({
        productSubmitLoading: false
      });
    } else {
      // no productId, new product
      try {
        let result = await addProduct(product);
        await this._getProductListByServiceItem(this.state.serviceItemId);
        this.setState({
          selectedProductId: "",
          addProductModalVisiable: false
        });
      } catch (e) {
        message.error(e.toString());
      }
      this.setState({
        productSubmitLoading: false
      });
    }
  }

  renderAddProductModal() {
    return (
      <div>
        <Modal
          title={this.state.selectedProductId ? "编辑商品" : "新建商品"}
          style={{ top: 20 }}
          visible={this.state.addProductModalVisiable}
          // onOk={this.handleOk}
          // onCancel={this.handleCancel}
          width={"80%"}
          footer={[]}
          onCancel={() => {
            this.setState({ addProductModalVisiable: false });
          }}
        >
          <ProductFrom
            serviceItemId={this.state.serviceItemId}
            productId={this.state.selectedProductId}
            onSubmit={this.onProductSubmit.bind(this)}
          />
          {/*{this.state.productSubmitLoading?(*/}
          {/*<div style={{position:"absolute", backgroundColor:"#aaaaaaaa"}}>*/}
          {/*<Spin size="large"/>*/}
          {/*</div>):null}*/}
        </Modal>
      </div>
    );
  }

  openAddProductModal() {
    this.setState({
      selectedProductId: "",
      addProductModalVisiable: true
    });
  }

  renderProductItem(product) {
    if (product.addNewProduct) {
      return (
        <List.Item>
          <Card title={"新建商品"}>
            {/*<Icon type="plus-square" theme="outlined" />*/}
            <div style={{ textAlign: "center" }}>
              <Button
                type="primary"
                shape="circle"
                icon="plus-square"
                size={"large"}
                onClick={this.openAddProductModal.bind(this)}
              ></Button>
            </div>
          </Card>
        </List.Item>
      );
    }
    return (
      <List.Item>
        <Card
          title={product.productName}
          actions={[
            <Icon
              type="setting"
              onClick={() => {
                this.setState({
                  addProductModalVisiable: true,
                  selectedProductId: product.productId
                });
              }}
            />
          ]}
        >
          <div>
            <span>价格：¥{product.price}</span>
          </div>
          <div>
            <span>佣金：{product.commission}</span>
          </div>
          <div>
            <span>所属用户：{product.userId}</span>
          </div>
          <div
            style={{
              overflow: "wrap",
              fontFamily: "monospace",
              overflowWrap: "break-word"
            }}
          >
            资源：{JSON.stringify(product.valueDefault)}
          </div>
        </Card>
      </List.Item>
    );
  }

  renderProducts() {
    return (
      <div>
        <List
          grid={{ gutter: 16, column: 4 }}
          dataSource={[...this.state.products, { addNewProduct: true }]}
          renderItem={this.renderProductItem.bind(this)}
        />
      </div>
    );
  }

  // //设置企业ID选项
  //     setOptions = () =>  {
  //         return this.state.orgInfor.map(element =>
  //             <Option key = {element.id} value = {element.id}>{element.name}</Option>
  //             );
  //     }

  render() {
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: { span: 4 },
      wrapperCol: { span: 20 }
    };
    // const options = {
    //     value : OrganizationIds,
    //     text : OrganizationNames
    // }
    return (
      <div className="contianer">
        <Spin spinning={this.state.serviceItemLoading}>
          <div className="section">
            <Row type="flex" justify="start">
              <Col span={10} className="title-text">
                <div>Service Item</div>
              </Col>
            </Row>
            <Row>
              <Form
                key={this.state.serviceItemId}
                onSubmit={this._handleSubmit}
                className="login-form"
              >
                <FormItem {...formItemLayout} label="服务项目ID">
                  {getFieldDecorator("serviceItemId", {
                    rules: [{ required: true, message: "请输入服务项目ID" }]
                  })(<Input disabled={true} />)}
                </FormItem>
                <FormItem {...formItemLayout} label="服务名称">
                  {getFieldDecorator("name", {
                    rules: [{ required: true, message: "请输入服务名称" }]
                  })(<Input placeholder={"Service Name"} />)}
                </FormItem>

                <FormItem {...formItemLayout} label="所属企业名称">
                  {getFieldDecorator("organizationIds", {
                    rules: [
                      { required: false, message: "请选择该服务所属企业名称" }
                    ]
                  })(
                    <Select placeholder={"Organization Name"}>
                      {this.state.orgInfor.map(org => (
                        <Option key={org.id} value={org.id}>
                          {org.name}
                        </Option>
                      ))}
                    </Select>
                  )}
                </FormItem>

                <FormItem {...formItemLayout} label="简介">
                  {getFieldDecorator("description", {
                    rules: [{ required: false, message: "请输入简介" }]
                  })(<Input placeholder={"Service Description"} />)}
                </FormItem>
                <FormItem {...formItemLayout} label="英语问候语">
                  {getFieldDecorator("greetings_en", {
                    rules: [{ required: false, message: "请输入英语问候语" }]
                  })(<Input placeholder={"Service English Greetings"} />)}
                </FormItem>
                <FormItem {...formItemLayout} label="中文问候语">
                  {getFieldDecorator("greetings_zh", {
                    rules: [{ required: false, message: "请输入中文问候语" }]
                  })(<Input placeholder={"Service 中文 Greetings"} />)}
                </FormItem>
                <FormItem {...formItemLayout} label="进程钥匙">
                  {getFieldDecorator("processKey", {
                    rules: [{ required: true, message: "请输入进程钥匙" }]
                  })(<Input placeholder={"Process Key"} />)}
                </FormItem>
                <FormItem {...formItemLayout} label="工作流变量">
                  {getFieldDecorator("wfVariables", {
                    rules: [
                      {
                        required: true,
                        message: "请输入工作流变量"
                      },
                      { validator: this.jsonValidatorThrottled }
                    ]
                  })(<TextArea rows={4} style={{ fontFamily: "monospace" }} />)}
                </FormItem>
                <FormItem {...formItemLayout} label="NO. of Customers">
                  {getFieldDecorator("numberOfCustomers", {
                    rules: [
                      {
                        required: true,
                        message: "Please input NO. of Customers"
                      }
                    ]
                  })(
                    <Select placeholder={"NO. of Customers"}>
                      <Option value="MANY">MANY</Option>
                      <Option value="ONE">ONE</Option>
                    </Select>
                  )}
                </FormItem>
                <FormItem {...formItemLayout} label="NO. of Providers">
                  {getFieldDecorator("numberOfProviders", {
                    rules: [
                      {
                        required: true,
                        message: "Please input NO. of Providers"
                      }
                    ]
                  })(
                    <Select placeholder={"NO. of Customers"}>
                      <Option value="MANY">MANY</Option>
                      <Option value="ONE">ONE</Option>
                    </Select>
                  )}
                </FormItem>
                <FormItem {...formItemLayout} label="ensureUnique">
                  {getFieldDecorator("ensureUnique", {
                    rules: [{ required: true, message: "ensureUnique" }],
                    valuePropName: "checked"
                  })(<Switch />)}
                </FormItem>
                <FormItem {...formItemLayout} label="promptForRestart">
                  {getFieldDecorator("promptForRestart", {
                    rules: [{ required: true, message: "promptForRestart" }],
                    valuePropName: "checked"
                  })(<Switch />)}
                </FormItem>
                <FormItem {...formItemLayout} label="muteCustomer">
                  {getFieldDecorator("muteCustomer", {
                    rules: [{ required: true, message: "muteCustomer" }],
                    valuePropName: "checked"
                  })(<Switch />)}
                </FormItem>
                <Button
                  type="primary"
                  onClick={() => {
                    this._handleSubmit();
                  }}
                  className="login-form-button"
                >
                  修改服务项目
                </Button>
              </Form>
            </Row>
          </div>
        </Spin>
        <div className="section">
          <Row type="flex" justify="start">
            <Col span={10} className="title-text">
              <div>关联商品</div>
            </Col>
          </Row>

          <Row>{this.renderProducts()}</Row>
        </div>
        {this.renderAddProductModal()}
      </div>
    );
  }
}

const ServiceItemForm = Form.create()(_ServiceItemForm);

export default ServiceItemForm;
