import React, { Component } from "react";
// import './App.css';
import "antd/dist/antd.css";

import "./WebContent.css";
import { Row, Col, Table, Icon, Button, notification, Modal } from "antd";
import moment from "moment";

import { getAllWebContent, deleteWebContentById } from "../../util/Shufu";
import { message } from "antd/lib/index";
import { Link } from "react-router-dom";
const confirm = Modal.confirm;

export default class WebContent extends Component {
  constructor(props) {
    super(props);
    this.columns = [
      {
        title: "时间",
        sorter: false,
        render: WebContent => {
          return moment(WebContent.createdTime).format("lll");
        }
      },
      {
        title: "类型",
        render: WebContent => {
          return WebContent.mediaType;
        }
      },
      {
        title: "标题",
        render: WebContent => {
          return WebContent.title;
        }
      },
      {
        title: "操作",
        key: "action",
        render: WebContent => {
          return (
            <span>
              <Link to={"/EditWebContent/" + WebContent.id}>详细内容</Link>
              <Button
                shape="circle"
                style={{ marginLeft: 20 }}
                onClick={() => this.onDelete(WebContent)}
              >
                <Icon type="delete" theme="outlined" />
              </Button>
            </span>
          );
        }
      }
    ];
    this.state = {
      collapsed: false,
      WebContent: [],
      pagination: {
        total: 1000,
        pageSize: 10
      },
      tableLoading: true
    };
    this.onDelete = this.onDelete.bind(this);
  }

  componentDidMount() {
    this._getAllWebContent();
  }

  async _getAllWebContent() {
    this.setState({
      tableLoading: false
    });
    try {
      let result = await getAllWebContent();

      console.log(result);
      this.setState({
        WebContent: result.javaApiResult
      });
    } catch (e) {
      message.error(e.toString());
    }
    this.setState({
      tableLoading: false
    });
  }

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });
  };

  onDelete(WebContent) {
    confirm({
      title: `你确定想删除${WebContent.title}`,
      content: "如果删错了是要被锤的",
      onOk: () => this._deleteWebContentById(WebContent.id),
      onCancel() {}
    });
  }

  async _deleteWebContentById(id) {
    try {
      await deleteWebContentById(id);
      let pos = 0;
      this.state.WebContent.forEach((each, index) => {
        if (each.id === id) {
          pos = index;
        }
      });
      let WebContent = JSON.parse(JSON.stringify(this.state.WebContent));
      WebContent.splice(pos, 1);
      this.setState({
        WebContent
      });
      this.openNotification("修改成功");
    } catch (e) {
      this.openBadNotifaction(JSON.stringify(e));
    }
  }

  openBadNotifaction(description) {
    notification.open({
      icon: <Icon type="frown" style={{ color: "red" }} />,
      message: "失败",
      description: description
    });
  }

  openNotification(description) {
    notification.open({
      icon: <Icon type="smile" style={{ color: "#108ee9" }} />,
      message: "成功",
      description: description
    });
  }

  render() {
    return (
      <div className="contianer">
        <div className="section">
          <Row type="flex" justify="start">
            <Col span={10} className="title-text">
              <div>舒辅新闻</div>
            </Col>
          </Row>
          <Row type="flex" justify="start">
            <div style={{ width: "100%" }}>
              <Table
                columns={this.columns}
                rowKey={WebContent => WebContent.id}
                dataSource={this.state.WebContent}
                pagination={this.state.pagination}
                loading={this.state.tableLoading}
                onChange={this.handleTableChange}
              />
            </div>
          </Row>
        </div>
      </div>
    );
  }
}
