import React, {Component} from 'react';
// import './App.css';
import 'antd/dist/antd.css';
import 'ant-design-pro/dist/ant-design-pro.css'
import Charts from 'ant-design-pro/lib/Charts';
import Trend from 'ant-design-pro/lib/Trend';

import locale from 'antd/lib/date-picker/locale/zh_CN';
import {
    G2,
    Chart,
    Geom,
    Axis,
    Tooltip as BizTooltip,
    Coord,
    Label,
    Legend,
    View,
    Guide,
    Shape,
    Facet,
    Util
} from "bizcharts";
import './ChuxinKPI.css'
import {
    Row,
    Col,
    Table,
    Icon,
    Button,
    notification,
    Modal,
    Steps,
    Transfer,
    Input,
    Spin,
    List,
    Avatar,
    Tag,
    Progress,
    Card,
    Tooltip,
    Tabs,
    DatePicker,
    message,
    Switch

} from 'antd';
// import moment from 'moment'
import DataSet from "@antv/data-set";

import _ from 'lodash'

import {
    getNewServiceInstanceStatistics,
    getLastPeriodDailyOrderMoney,
    getLastPeriodDailyFormCount,
    getLastPeriodDailyNewCDSCount,
    getKPI
} from '../../util/Shufu';
import CopyServiceItem from "../CopyServiceItem/CopyServiceItem";

const {ChartCard, Field, MiniArea, MiniBar, MiniProgress, Pie, yuan} = Charts;
const {MonthPicker, RangePicker, WeekPicker} = DatePicker;
const ButtonGroup = Button.Group;

const TabPane = Tabs.TabPane;


const serviceItemId = require("../../util/config").CHUXIN_SERVICE_ITEM_ID;

const moment = require('moment-timezone')

export default class ChuxinKPI extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedPeriod: "thisWeek",
            lastNewServiceInstanceCounts: [],
            lastNewServiceInstanceCountsSum: 0,
            lastPeriodDailyOrderMoney: [],
            lastPeriodDailyOrderMoneySum: 0,
            lastPeriodDailyFormDataCount: [],
            lastPeriodDailyFormDataCountSum: 0,
            lastPeriodDailyNewCDSCount: [],
            lastPeriodDailyNewCDSCountSum: 0,
            startDate: moment().day(1).startOf('day'),
            endDate: moment().day(7).endOf('day'),
            loading: true,
            forceBeijingTime: true,
            kpis: {
                questionsAsked: [],
                customersUsed: [],
                questionsAnswered: [],
                customersFinishedTodos: [],
                numberOfErrors: [],
            }

        }
    }

    _myMoment(input) {
        if (this.state.forceBeijingTime) {
            if (input) {
                return moment.tz(input, "Asia/Shanghai")
            }
            else {
                return moment.tz("Asia/Shanghai")
            }
        } else {
            return moment(input)
        }
    }

    componentDidMount() {
        this._getLastNewServiceInstanceCounts();
        this._getLastPeriodDailyOrderMoney();
        this._getLastPeriodDailyFormCount();
        this._getLastPeriodDailyNewCDSCount();
        this._getKPI()
    };

    filterData(input=[],type){
        if(type==='thisWeek'){
            let startDate = this._myMoment().day(1).startOf('day').valueOf();
            let endDate = this._myMoment().day(7).endOf('day').valueOf();
            return input.filter(data=>{
                let date = new Date(data).getTime();
                return date >= startDate && date <= endDate
            })
        }else if(type==='lastWeek'){
            let startDate = this._myMoment().day(1).subtract(1, 'weeks').startOf('day').valueOf();
            let endDate = this._myMoment().day(7).subtract(1, 'weeks').endOf('day').valueOf();
            return input.filter(data=> {
                let date = new Date(data).getTime();
                return date >= startDate && date <= endDate
            })
        }
        return input
    }

    async _getLastNewServiceInstanceCounts() {

        try {
            const now = new Date().getTime();
            const start = now - 30 * 24 * 3600 * 1000;

            let results = await getNewServiceInstanceStatistics({
                serviceItemId,
                start
            })
            let data = results.data;
            let sum = 0;
            let _lastNewServiceInstanceCounts = data.map((d) => {
                sum += d.doc_count;
                return {
                    x: moment(new Date(d.date)).format('YYYY-MM-DD'),
                    y: d.doc_count
                }
            });

            let thisWeekData = this.filterData(data,'thisKeer')
            let lastWeekData = this.filterData(data,'lastWeek')

            console.log({
                thisWeekData,
                lastWeekData
            })

            this.setState({
                lastNewServiceInstanceCounts: _lastNewServiceInstanceCounts,
                lastNewServiceInstanceCountsSum: sum
            });
        }
        catch (e) {
            message.error(e.toString());
        }

    }

    async _getLastPeriodDailyOrderMoney() {
        try {
            const now = new Date().getTime();
            const start = now - 30 * 24 * 3600 * 1000;

            let results = await getLastPeriodDailyOrderMoney({
                serviceItemId,
                start
            })
            let data = results.data;
            let sum = 0;
            let _lastNewServiceInstanceCounts = data.map((d) => {
                sum += parseFloat(d.sum);
                return {
                    x: d.date,
                    y: d.sum
                }
            });
            this.setState({
                lastPeriodDailyOrderMoney: _lastNewServiceInstanceCounts,
                lastPeriodDailyOrderMoneySum: sum
            });
        }
        catch (e) {
            message.error(e.toString());
        }

    }

    async _getLastPeriodDailyFormCount() {

        try {
            const now = new Date().getTime();
            const start = now - 30 * 24 * 3600 * 1000;

            let results = await getLastPeriodDailyFormCount({
                serviceItemId,
                start
            })
            let data = results.data;
            let sum = 0;
            let _counts = data.map((d) => {
                sum += d.doc_count;
                return {
                    x: moment(new Date(d.date)).format('YYYY-MM-DD'),
                    y: d.doc_count
                }
            });
            this.setState({
                lastPeriodDailyFormDataCount: _counts,
                lastPeriodDailyFormDataCountSum: sum
            });
        } catch (e) {
            message.error(e.toString());
        }

    }

    async _getLastPeriodDailyNewCDSCount() {

        try {
            const now = new Date().getTime();
            const start = now - 30 * 24 * 3600 * 1000;

            let results = await getLastPeriodDailyNewCDSCount({
                serviceItemId,
                start
            })
            let data = results.data;
            let sum = 0;
            let _counts = data.map((d) => {
                sum += d.doc_count;
                return {
                    x: moment(new Date(d.date)).format('YYYY-MM-DD'),
                    y: d.doc_count
                }
            });
            this.setState({
                lastPeriodDailyNewCDSCount: _counts,
                lastPeriodDailyNewCDSCountSum: sum
            });
        } catch (e) {
            message.error(e.toString());
        }


    }


    async _getKPI() {

        try {

            let result = await getKPI({
                company: 'chuxin',
                dateFrom: new Date(this.state.startDate).getTime(),
                dateTo: new Date(this.state.endDate).getTime()
            });
            let kpiResult = result.javaApiResult
            console.log(kpiResult);

            let userIds = Object.keys(kpiResult);
            let keys = []
            if (userIds && userIds[0]) {
                keys = Object.keys(kpiResult[userIds[0]])
            }
            ;

            let kpis = {}

            for (let i = 0; i < keys.length; i++) {
                let key = keys[i];
                let tempResult = []


                userIds.map(userId => {
                    tempResult.push({
                        userId,
                        count: kpiResult[userId][key]
                    })
                });

                tempResult = tempResult.sort((a, b) => {
                    return b.count - a.count
                })
                kpis[key] = tempResult
            }
            this.setState({
                loading: false,
                kpis
            })
        } catch (e) {
            message.error(e.toString());
        }


    }


    renderFirstRow() {
        return (<Row gutter={16}>
                <Col span={6}>
                    <ChartCard
                        title="近30天新服务启动数"
                        total={(<div>
                            <span>
                                {this.state.lastNewServiceInstanceCountsSum}
                            </span>
                            <span style={{marginLeft: 16,fontSize:14}}>
                                上周
                                <Trend style={{marginLeft: 8, color: "rgba(0,0,0,.85)"}}>
                                12%
                                </Trend>
                            </span>
                            <span style={{marginLeft: 16,fontSize:14}}>
                                本周
                                <Trend style={{marginLeft: 8, color: "rgba(0,0,0,.85)"}}>
                                12%
                                </Trend>
                            </span>
                        </div>)}
                        contentHeight={50}
                        footer={(<div style={{textAlign:"center"}}> <span>
                                周同比
                                <Trend flag="up" style={{marginLeft: 8, color: "rgba(0,0,0,.85)"}}>
                                12%
                                </Trend>
                            </span></div>)}

                    >
                        <MiniArea
                            line
                            height={50}
                            data={this.state.lastNewServiceInstanceCounts}
                            borderColor={"#2979ff"}
                            color={"#2979ff33"}
                        />
                    </ChartCard>
                </Col>
                <Col span={6}>
                    <ChartCard
                        title="近30天付费咨询购买金额"
                        total={`¥ ${this.state.lastPeriodDailyOrderMoneySum.toFixed(2)}`}
                        contentHeight={40}
                    >
                        <MiniArea
                            line
                            height={50}
                            data={this.state.lastPeriodDailyOrderMoney}
                            borderColor={"#8664e1"}
                            color={"#8664e133"}
                        />
                    </ChartCard>
                </Col>
                <Col span={6}>
                    <ChartCard
                        title="近30天填写表格数"
                        total={this.state.lastPeriodDailyFormDataCountSum}
                        action={<Tooltip title="仅统计最新的1万个Service Instance中产生的数据"><Icon type="info-circle-o"/></Tooltip>}

                        contentHeight={40}
                    >
                        <MiniArea
                            line
                            height={50}
                            data={this.state.lastPeriodDailyFormDataCount}
                            borderColor={"#f1c40f"}
                            color={"#f1c40f33"}
                        />
                    </ChartCard>
                </Col>
                <Col span={6}>
                    <ChartCard
                        title="近30天新增CDS数"
                        total={this.state.lastPeriodDailyNewCDSCountSum}
                        action={<Tooltip title="仅统计最新的1万个Service Instance中产生的数据"><Icon type="info-circle-o"/></Tooltip>}

                        contentHeight={40}
                    >
                        <MiniArea
                            line
                            height={50}
                            data={this.state.lastPeriodDailyNewCDSCount}
                            borderColor={"#2ecc71"}
                            color={"#2ecc7133"}
                        />
                    </ChartCard>
                </Col>
            </Row>
        )
    }

    clickPeriod(periodName) {
        this.setState({
            selectedPeriod: periodName,

        });
        let startDate = this._myMoment();
        let endDate = this._myMoment();

        if (periodName === 'thisWeek') {
            startDate = this._myMoment().day(1).startOf('day');
            endDate = this._myMoment().day(7).endOf('day');
        }
        else if (periodName === 'lastWeek') {
            startDate = this._myMoment().day(1).subtract(1, 'weeks').startOf('day');
            endDate = this._myMoment().day(7).subtract(1, 'weeks').endOf('day');
        }
        else if (periodName === 'thisMonth') {
            startDate = this._myMoment().startOf('month').startOf('day');
            endDate = this._myMoment().endOf('month').endOf('day');
        }
        else if (periodName === 'lastMonth') {
            startDate = this._myMoment().subtract(1, 'months').startOf('month').startOf('day');
            endDate = this._myMoment().subtract(1, 'months').endOf('month').endOf('day');
        }

        // console.log({
        //     startDate,
        //     endDate,
        //     a:startDate.toString()
        // })

        this.setState({
            startDate,
            endDate,
            loading: true
        }, () => {
            this._getKPI()
        });


    }

    changePeriod(period) {
        let startDate = this._myMoment(period[0].format('YYYY-MM-DD HH:mm:ss'));
        let endDate = this._myMoment(period[1].format('YYYY-MM-DD HH:mm:ss'));
        this.setState({
            startDate,
            endDate,
            selectedPeriod: "",
            loading: true
        }, () => {
            this._getKPI()
        });
    }

    setForceBeijingTime(forceBeijingTime) {
        this.setState({forceBeijingTime},
            () => {
                let startDate = this._myMoment(this.state.startDate.format('YYYY-MM-DD HH:mm:ss'));
                let endDate = this._myMoment(this.state.endDate.format('YYYY-MM-DD HH:mm:ss'));
                this.setState({
                    startDate,
                    endDate,
                    loading: true
                }, () => {
                    this._getLastNewServiceInstanceCounts();
                    this._getLastPeriodDailyOrderMoney();
                    this._getLastPeriodDailyFormCount();
                    this._getLastPeriodDailyNewCDSCount();
                    this._getKPI()
                });

            })
    }

    _renderTabExtraContent() {
        return (<div>
            <span>
                <span style={{paddingRight: 10}}>
                    <span>
                        <Tooltip title="强制转换时区为北京时间">
                        <Switch checkedChildren={<Icon type="clock-circle"/>}
                                unCheckedChildren={<Icon type="clock-circle"/>} defaultChecked={true}
                                onChange={this.setForceBeijingTime.bind(this)}/>
                        </Tooltip>
                    </span>

                </span>
                <ButtonGroup className={'date-button-group'}>
                    <Button style={{paddingLeft: 3, paddingRight: 3}}
                            className={this.state.selectedPeriod === 'thisWeek' ? "selectedPeriodButton" : ""}
                            onClick={this.clickPeriod.bind(this, "thisWeek")}>本周</Button>
                    <Button style={{paddingLeft: 3, paddingRight: 3}}
                            className={this.state.selectedPeriod === 'lastWeek' ? "selectedPeriodButton" : ""}
                            onClick={this.clickPeriod.bind(this, "lastWeek")}>上周</Button>
                    <Button style={{paddingLeft: 3, paddingRight: 3}}
                            className={this.state.selectedPeriod === 'thisMonth' ? "selectedPeriodButton" : ""}
                            onClick={this.clickPeriod.bind(this, "thisMonth")}>本月</Button>
                    <Button style={{paddingLeft: 3, paddingRight: 3}}
                            className={this.state.selectedPeriod === 'lastMonth' ? "selectedPeriodButton" : ""}
                            onClick={this.clickPeriod.bind(this, "lastMonth")}>上月</Button>
                    <RangePicker style={{width: 230}}
                                 value={[this._myMoment(this.state.startDate, "YYYY-MM-DD"), this._myMoment(this.state.endDate, "YYYY-MM-DD")]}
                                 onChange={this.changePeriod.bind(this)}
                    />

                </ButtonGroup>
            </span>
            <span style={{width: 100}}>
            </span>
        </div>)
    }


    renderQuestionsAskedChart() {
        return (<div>
            <Chart padding={[30, 0, 30, 30]} height={400} data={this.state.kpis.questionsAsked} forceFit>
                <Coord/>
                <Axis
                    name="userId"
                />
                <Axis name="count"/>
                <BizTooltip/>
                <Geom type="interval" position="userId*count" color='#f1c40f' animate={{
                    appear: {
                        animation: 'scaleInY',
                        easing: 'easeQuadIn',
                        delay: 200,
                        duration: 500
                    },
                    update: {
                        animation: 'fanIn',
                        easing: 'easeQuadIn',
                        delay: 200,
                        duration: 500
                    },
                    enter: {
                        animation: 'scaleInY',
                        easing: 'easeQuadIn',
                        delay: 200,
                        duration: 500
                    }
                }}/>
            </Chart>
        </div>)
    }

    renderCustomersUsedChart() {
        return (<div>
            <Chart padding={[30, 0, 30, 30]} height={400} data={this.state.kpis.customersUsed} forceFit>
                <Coord/>
                <Axis
                    name="userId"
                />
                <Axis name="count"/>
                <BizTooltip/>
                <Geom type="interval" position="userId*count" color='#2979ff' animate={{
                    appear: {
                        animation: 'scaleInY',
                        easing: 'easeQuadIn',
                        delay: 200,
                        duration: 500
                    },
                    update: {
                        animation: 'fanIn',
                        easing: 'easeQuadIn',
                        delay: 200,
                        duration: 500
                    },
                    enter: {
                        animation: 'scaleInY',
                        easing: 'easeQuadIn',
                        delay: 200,
                        duration: 500
                    }
                }}
                />
            </Chart>
        </div>)
    }

    renderCustomersFinishedTodosChart() {
        return (<div>
            <Chart padding={[30, 0, 30, 30]} height={400} data={this.state.kpis.customersFinishedTodos} forceFit>
                <Coord/>
                <Axis
                    name="userId"
                />
                <Axis name="count"/>
                <BizTooltip/>
                <Geom type="interval" position="userId*count" color='#9b59b6' animate={{
                    appear: {
                        animation: 'scaleInY',
                        easing: 'easeQuadIn',
                        delay: 200,
                        duration: 500
                    },
                    update: {
                        animation: 'fanIn',
                        easing: 'easeQuadIn',
                        delay: 200,
                        duration: 500
                    },
                    enter: {
                        animation: 'scaleInY',
                        easing: 'easeQuadIn',
                        delay: 200,
                        duration: 500
                    }
                }}
                />
            </Chart>
        </div>)
    }

    renderQuestionsAnsweredChart() {
        return (<div>
            <Chart padding={[30, 0, 30, 30]} height={400} data={this.state.kpis.questionsAnswered} forceFit>
                <Coord/>
                <Axis
                    name="userId"
                />
                <Axis name="count"/>
                <BizTooltip/>
                <Geom type="interval" position="userId*count" color='#2979ff' animate={{
                    appear: {
                        animation: 'scaleInY',
                        easing: 'easeQuadIn',
                        delay: 200,
                        duration: 500
                    },
                    update: {
                        animation: 'fanIn',
                        easing: 'easeQuadIn',
                        delay: 200,
                        duration: 500
                    },
                    enter: {
                        animation: 'scaleInY',
                        easing: 'easeQuadIn',
                        delay: 200,
                        duration: 500
                    }
                }}
                />
            </Chart>
        </div>)
    }

    renderNumberOfErrorsChart() {
        return (<div>
            <Chart padding={[30, 0, 30, 30]} height={400} data={this.state.kpis.numberOfErrors} forceFit>
                <Coord/>
                <Axis
                    name="userId"
                />
                <Axis name="count"/>
                <BizTooltip/>
                <Geom type="interval" position="userId*count" color='#e74c3c' animate={{
                    appear: {
                        animation: 'scaleInY',
                        easing: 'easeQuadIn',
                        delay: 200,
                        duration: 500
                    },
                    update: {
                        animation: 'fanIn',
                        easing: 'easeQuadIn',
                        delay: 200,
                        duration: 500
                    },
                    enter: {
                        animation: 'scaleInY',
                        easing: 'easeQuadIn',
                        delay: 200,
                        duration: 500
                    }
                }}
                />
            </Chart>
        </div>)
    }

    renderSecondRow() {
        return (<Row gutter={16} style={{marginTop: 16}}>
            <Col span={24}>
                <Spin spinning={this.state.loading}>
                    <Card style={{}}>
                        <Tabs defaultActiveKey="1" tabBarExtraContent={this._renderTabExtraContent()} tabBarGutter={0}>

                            <TabPane tab="联系客户数" key="1">{this.renderCustomersUsedChart()}</TabPane>
                            <TabPane tab="活跃客户数" key="2">{this.renderCustomersFinishedTodosChart()}</TabPane>
                            <TabPane tab="用户提问数" key="3">{this.renderQuestionsAskedChart()}</TabPane>
                            <TabPane tab="回答提问数" key="4">{this.renderQuestionsAnsweredChart()}</TabPane>
                            <TabPane tab="错误数" key="5">{this.renderNumberOfErrorsChart()}</TabPane>
                        </Tabs>
                    </Card>
                </Spin>
            </Col>
        </Row>)
    }

    render() {
        return (<div className={'page-container'}>
            {this.renderFirstRow()}
            {this.renderSecondRow()}
        </div>)

    }
}