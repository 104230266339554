import React from "react";
import "antd/dist/antd.css";

import { Card, Input, message, Avatar, Button, Modal } from "antd";
import { getUser, deleteUserComplete } from "../../util/Shufu";

const { Search } = Input;
const { Meta } = Card;

export default class Homepage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null
    };
    this.searchUser = this.searchUser.bind(this);
    this.deleteUser = this.deleteUser.bind(this);
  }
  async componentDidMount() {
    // let siuvoSessionToken = Cookies.get('SIUVO_SUPPORT_SESSION');
    // if(siuvoSessionToken){
    //
    // }

    this.setState({});
  }

  async searchUser(userId) {
    try {
      let result = await getUser(userId);
      this.user = result.javaApiResult;
      this.setState({
        user: result.javaApiResult
      });
    } catch (e) {
      message.error(e.toString());
    }
  }

  deleteUser(userId) {
    return new Promise(async (resolve, reject) => {
      try {
        await deleteUserComplete(userId);
        this.setState({ user: null });
        message.success("删除成功");
        resolve();
      } catch (e) {
        message.error(e.toString());
        reject(e);
      }
    });
  }

  renderCardContent() {
    if (this.state.user) {
      return (
        <Meta
          avatar={
            <Avatar
              src={
                this.state.user.profilePhoto ||
                "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
              }
            />
          }
          title={
            this.state.user.name ||
            this.state.user.nickname ||
            this.state.user.id
          }
          description={
            <div>
              <code
                style={{
                  wordBreak: "normal",
                  textAlign: "left",
                  display: "block",
                  whiteSpace: "pre-wrap",
                  border: "1px solid #f0f0f0",
                  padding: 5,
                  margin: 5
                }}
              >
                {JSON.stringify(this.state.user, null, 4)}
              </code>
              <div>
                <Button.Group size="large">
                  <Button
                    type="primary"
                    onClick={() => {
                      this.setState({ user: null });
                    }}
                  >
                    重新搜索
                  </Button>
                  <Button
                    type="danger"
                    onClick={() => {
                      Modal.confirm({
                        title: "确认操作",
                        content: `您确定要删除用户【${this.state.user.name ||
                          this.state.user.nickname ||
                          this.state.user
                            .id}】吗？一旦删除，该用户数据将会堙灭在浩瀚宇宙中，永远无法恢复！请再三确认后操作！`,
                        okText: "确认删除",
                        cancelText: "取消",
                        onOk: () => {
                          return this.deleteUser(this.state.user.id);
                        }
                      });
                    }}
                  >
                    确认删除
                  </Button>
                </Button.Group>
              </div>
            </div>
          }
        />
      );
    } else {
      return (
        <Search
          placeholder="请输入用户ID"
          enterButton="Search"
          size="large"
          onSearch={value => this.searchUser(value)}
          style={{ maxWidth: 500 }}
        />
      );
    }
  }

  render() {
    return (
      <div style={{ textAlign: "center" }}>
        <Card
          title="删除用户"
          style={{ width: "70%", margin: "auto", marginTop: 50 }}
        >
          {this.renderCardContent()}
        </Card>
      </div>
    );
  }
}
