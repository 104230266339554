import React, { Component } from "react";

class ServerIndicator extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return <div style={{ color: "white" }}>🇺🇸 SIT</div>;
  }
}

export default ServerIndicator;
