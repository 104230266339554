import React, { Component } from "react";
// import './App.css';
import "antd/dist/antd.css";

import {
  Row,
  Col,
  Table,
  Input,
  Form,
  Icon,
  Select,
  Switch,
  List,
  Card,
  Button,
  InputNumber
} from "antd";
import _ from "lodash";

import { getProduct } from "../../util/Shufu";
import { message } from "antd/lib/index";

const Search = Input.Search;
const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;

class _ProductForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      serviceItemId: props.serviceItemId,
      productId: props.productId,
      product: {}
    };
  }

  async componentWillReceiveProps(newProps) {
    this.setState({
      serviceItemId: newProps.serviceItemId,
      productId: newProps.productId
    });

    if (newProps.productId && newProps.productId !== this.props.productId) {
      this.getProductById(newProps.productId);
    }

    // if (newProps.serviceItemId !== this.props.serviceItemId) {
    //     await this._getServiceItemById(newProps.serviceItemId)
    //     await this._getProductListByServiceItem(newProps.serviceItemId)
    // }
  }

  async getProductById(productId) {
    try {
      let result = await getProduct(productId);
      this.props.form.setFieldsValue({
        productId,
        productName: result.productName,
        price: result.price,
        commission: result.commission,
        userId: result.userId,
        productDesc: result.productDesc,
        productType: result.productType,
        currency: result.currency,
        valueDefault: JSON.stringify(result.valueDefault, null, 2)
      });
    } catch (e) {
      message.error(e.toString());
    }
  }

  async componentDidMount() {
    if (this.props.serviceItemId) {
      // alert("aaaa")
      this.props.form.setFieldsValue({
        serviceItemId: this.props.serviceItemId
      });
    }
    if (this.props.productId) {
      this.getProductById(this.props.productId);
    }
  }

  async _handleSubmit() {
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        console.log("Received values of form: ", values);
        if (this.props.onSubmit) {
          values.valueDefault = JSON.parse(values.valueDefault);
          this.props.onSubmit(values);
        }
        this.setState({
          loading: false
        });
      } else {
        this.setState({
          loading: false
        });
      }
    });
  }

  jsonValidatorThrottled = _.debounce(function(rule, value, callback) {
    try {
      JSON.parse(value);
      callback();
    } catch (e) {
      callback("错误的JSON格式: " + e.toString());
    }
  }, 1000);

  render() {
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 }
    };
    return (
      <div className="contianer">
        <div className="section">
          <Row>
            <Form
              key={this.state.serviceItemId}
              onSubmit={this._handleSubmit}
              className="login-form"
            >
              <Row type="flex" justify="space-between">
                <Col span={12}>
                  <FormItem {...formItemLayout} label="商品ID">
                    {getFieldDecorator("productId", {
                      rules: [{ required: false, message: "商品ID" }]
                    })(<Input disabled={true} />)}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem {...formItemLayout} label="所属服务项目">
                    {getFieldDecorator("serviceItemId", {
                      rules: [{ required: false, message: "所属服务项目" }]
                    })(<Input disabled={true} />)}
                  </FormItem>
                </Col>
              </Row>

              <Row type="flex" justify="space-between">
                <Col span={12}>
                  <FormItem {...formItemLayout} label="商品名称">
                    {getFieldDecorator("productName", {
                      rules: [{ required: true, message: "请输入商品名称" }]
                    })(<Input placeholder={"商品名称"} />)}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem {...formItemLayout} label="商品货币">
                    {getFieldDecorator("currency", {
                      rules: [{ required: true, message: "请选择商品货币" }]
                    })(
                      <Select placeholder={"货币"}>
                        <Option value="CNY">人民币</Option>
                        {/*<Option value="ONE">ONE</Option>*/}
                      </Select>
                    )}
                  </FormItem>
                </Col>
              </Row>
              <Row type="flex" justify="space-between">
                <Col span={12}>
                  <FormItem {...formItemLayout} label="商品价格">
                    {getFieldDecorator("price", {
                      rules: [{ required: true, message: "请输入商品价格" }]
                    })(
                      <InputNumber
                        min={0}
                        step={0.01}
                        precision={2}
                        style={{ width: 100 }}
                      />
                    )}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem {...formItemLayout} label="佣金价格">
                    {getFieldDecorator("commission", {
                      rules: [{ required: true, message: "请输入佣金价格" }]
                    })(<InputNumber min={0} step={0.01} precision={2} />)}
                  </FormItem>
                </Col>
              </Row>
              <Row type="flex" justify="space-between">
                <Col span={12}>
                  <FormItem {...formItemLayout} label="商品拥有者">
                    {getFieldDecorator("userId", {
                      rules: [{ required: true, message: "请输入商品拥有者" }]
                    })(<Input placeholder={"商品拥有者"} />)}
                  </FormItem>
                </Col>

                <Col span={12}>
                  <FormItem {...formItemLayout} label="商品类型">
                    {getFieldDecorator("productType", {
                      rules: [{ required: true, message: "请选择商品类型" }]
                    })(
                      <Select placeholder={"NO. of Customers"}>
                        <Option value="ASK_QUESTION">问问题</Option>
                        {/*<Option value="ONE">ONE</Option>*/}
                      </Select>
                    )}
                  </FormItem>
                </Col>
              </Row>
              <Row type="flex" justify="space-between">
                <Col span={12}>
                  <FormItem {...formItemLayout} label="商品描述">
                    {getFieldDecorator("productDesc", {
                      rules: [
                        {
                          required: false,
                          message: "Please input Service Description"
                        }
                      ]
                    })(<TextArea rows={4} />)}
                  </FormItem>
                </Col>

                <Col span={12}>
                  <FormItem {...formItemLayout} label="商品资源">
                    {getFieldDecorator("valueDefault", {
                      rules: [
                        { required: true, message: "请输入商品资源" },
                        { validator: this.jsonValidatorThrottled }
                      ]
                    })(
                      <TextArea rows={4} style={{ fontFamily: "monospace" }} />
                    )}
                  </FormItem>
                </Col>
              </Row>
            </Form>
          </Row>
        </div>

        <div style={{ textAlign: "center" }}>
          <Button
            type="primary"
            size={"large"}
            onClick={() => {
              this._handleSubmit();
            }}
            block
          >
            确定
          </Button>
        </div>
      </div>
    );
  }
}

const ProductForm = Form.create()(_ProductForm);

export default ProductForm;
