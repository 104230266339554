import React, { Component } from 'react';
// import './App.css';
import 'antd/dist/antd.css';

import { Row, Col, Spin, Input, Form, Icon, Select, Switch, List, Card, Button, Avatar, Steps, Tag } from 'antd';
import _ from 'lodash'

import { searchUser, getServiceItemByProviderId, createWechatQr } from '../../util/Shufu'
import { message } from "antd/lib/index";

import EditableTagGroup from '../EditableTagGroup/EditableTagGroup'

const Search = Input.Search;
const FormItem = Form.Item;
const Option = Select.Option
const { TextArea } = Input


class _OtherVariablesForm extends Component {

    constructor(props) {
        super(props);
    }

    async componentWillReceiveProps(newProps) {
        if (JSON.stringify(newProps) !== JSON.stringify(this.props)) {
            this.props.form.setFieldsValue({
                tags: newProps.tags,
                wfVariables: JSON.stringify(JSON.parse(newProps.wfVariables || '{}'), null, 2)
            })
        }
    }

    async componentDidMount() {
        this.props.form.setFieldsValue({
            tags: this.props.tags,
            wfVariables: JSON.stringify(JSON.parse(this.props.wfVariables || '{}'), null, 2)
        })
    }

    async _handleSubmit() {
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);
                if (this.props.onSubmit) {
                    this.props.onSubmit(values)
                }
                this.setState({
                    loading: false
                })
            } else {
                console.log({ err })
                this.setState({
                    loading: false
                })
            }

        });
    }

    jsonValidatorThrottled = _.debounce(function (rule, value, callback) {
        try {
            JSON.parse(value)
            callback()
        }
        catch (e) {
            callback("错误的JSON格式: " + e.toString())
        }
    }, 1000)

    render() {
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: { span: 4 },
            wrapperCol: { span: 20 },
        };
        return (

            <div className='contianer'>
                <div className='section'>
                    <Row>
                        <Form key={this.props.id} onSubmit={this._handleSubmit} className="login-form">
                            <FormItem  {...formItemLayout} label="工作流变量">
                                {getFieldDecorator('wfVariables', {
                                    rules: [{ required: true, message: '请输入工作流变量' },
                                    { validator: this.jsonValidatorThrottled }]

                                })(
                                    <TextArea rows={8} style={{ fontFamily: "monospace" }} />
                                )}
                            </FormItem>

                            <FormItem  {...formItemLayout} label="标签">
                                {getFieldDecorator('tags', {
                                    rules: [],
                                    valuePropName: "tags"

                                })(
                                    <EditableTagGroup />
                                )}
                            </FormItem>

                        </Form>
                    </Row>
                </div>

                <div style={{ textAlign: "center" }}>
                    <Button type="primary" size={'large'} onClick={() => {
                        this._handleSubmit()
                    }}>下一步</Button>
                </div>


            </div>
        );
    }
}

const OtherVariablesForm = Form.create()(_OtherVariablesForm);


export default class CreateWechatQrcodeForm extends Component {

    constructor(p) {
        super(p);
        this.state = {
            step: 0,
            provider: null,
            providerList: [],
            serviceItemList: [],
            selectedProvider: null,
            selectedServiceItem: null,
            tags: [],
            wfVariables: "{}",
            resultUrl: "",
            loading: false


        }
    }


    async _searchProvider(keyword) {
        console.log({ keyword })
        if (!keyword) {
            return
        }
        try {
            let result = await searchUser({ offset: 0, limit: 30, keyword });
            console.log(result)

            this.setState({
                providerList: result.data
            })
        } catch (e) {
            message.error(e.toString());

        }


    }

    async _getServiceItemByProviderId(providerId) {
        if (!providerId) {
            return
        }
        this.setState({
            loading: true
        })

        try {
            let result = await getServiceItemByProviderId(providerId);
            console.log(result)

            this.setState({
                serviceItemList: result.javaApiResult
            })
        } catch (e) {
            message.error(e.toString());

        }
        this.setState({
            loading: false
        })
    }

    _searchProviderThrottled = _.throttle(this._searchProvider, 1000);

    async _generateQrCode(sceneData) {
        this.setState({
            loading: true
        })

        try {
            const result = await createWechatQr(sceneData);
            const resultUrl = result.javaApiResult && result.javaApiResult.imgUrl;
            const resultSceneId = result.javaApiResult && result.javaApiResult.sceneId;

            this.setState({
                resultSceneId,
                resultUrl,
                loading: false,
                step: 4
            })
        }
        catch (e) {
            message.error(e.toString());

        }
        this.setState({
            loading: false
        })

    }

    selectProvider(provider) {
        this.setState({
            selectedProvider: provider,
            step: 1
        })

        this._getServiceItemByProviderId(provider.id)
    }

    selectServiceItem(serviceItem) {
        this.setState({
            selectedServiceItem: serviceItem,
            step: 2
        })
    }

    fillInOtherVariables(value) {
        this.setState({
            tags: value.tags || [],
            wfVariables: value.wfVariables || "{}",
            step: 3
        })
    }

    renderSelectProvider() {
        if (this.state.step === 0) {
            let searchBar = (<Search
                placeholder="搜索医生"
                onSearch={value => this._searchProvider(value)}
                onChange={(event) => {
                    event.persist();
                    this._searchProviderThrottled(event.target.value)
                }}
                enterButton
            />);


            let result = (<List
                grid={{ gutter: 16, column: 4 }}
                dataSource={this.state.providerList}
                renderItem={item => (
                    <List.Item>
                        <Card title={item.id} extra={<Button type="primary" size="small" onClick={() => {
                            this.selectProvider(item)
                        }}>
                            选择
                        </Button>}>
                            <Card.Meta
                                avatar={<Avatar src={item.profilePhoto} size={30} />}
                                title={item.name}
                                description={item.providerProfile && item.providerProfile.description}
                            />

                        </Card>
                    </List.Item>
                )}
            />)


            return (<div>
                <div>
                    {searchBar}

                </div>
                <div style={{ marginTop: 20 }}>
                    {result}
                </div>
            </div>)

        } else {
            return null
        }

    }

    renderSelectServiceItem() {
        if (this.state.step === 1) {
            let result = (<List
                grid={{ gutter: 16, column: 4 }}
                dataSource={this.state.serviceItemList}
                renderItem={serviceItem => (
                    <List.Item>
                        <Card title={serviceItem.name}
                            actions={[(<Button type="primary" size="small" onClick={() => {
                                this.selectServiceItem(serviceItem)
                            }}> 选择</Button>)]}
                        >
                            <div>
                                {serviceItem.description || "无描述信息..."}
                            </div>
                        </Card>
                    </List.Item>
                )}
            />)


            return (<div>
                <div>
                    <Button size={'small'} type="dashed" onClick={() => {
                        this.setState({
                            step: 0,
                            selectedProvider: null,
                            selectedServiceItem: null,
                        })
                    }}>
                        <Icon type="left" />返回上一步
                    </Button>
                </div>
                <div style={{ marginTop: 20 }}>
                    {result}
                </div>
            </div>)
        } else {
            return null
        }
    }

    renderFillOtherVariables() {
        if (this.state.step === 2) {
            return (<div>
                <div>
                    <Button size={'small'} type="dashed" onClick={() => {
                        this.setState({
                            step: 1,
                        })
                    }}>
                        <Icon type="left" />返回上一步
                    </Button>
                </div>
                <div style={{ marginTop: 20 }}>
                    <OtherVariablesForm wfVariables={this.state.wfVariables} tags={this.state.tags}
                        onSubmit={(value) => {
                            this.fillInOtherVariables(value)
                        }} />
                </div>
            </div>)

        } else {
            return null
        }
    }

    renderConfirm() {
        if (this.state.step === 3) {


            let finalResult = {
                "rc": this.state.selectedProvider.providerProfile && this.state.selectedProvider.providerProfile.referralCode || "",
                "wfVariables": JSON.stringify(JSON.parse(this.state.wfVariables || "{}")),
                "pid": this.state.selectedProvider.id,
                "sn": this.state.selectedServiceItem.name,
                "sid": this.state.selectedServiceItem.id,
                "tags": JSON.stringify(this.state.tags)
            }

            return (<div>
                <div>
                    <Button size={'small'} type="dashed" onClick={() => {
                        this.setState({
                            step: 2,
                        })
                    }}>
                        <Icon type="left" />返回上一步
                    </Button>
                </div>
                <div style={{ marginTop: 20 }}>
                    <Row justify="space-around" type="flex" gutter={20}>
                        <Col span={10}>
                            <div style={{ padding: 10 }}>
                                <div>
                                    医生
                                </div>
                                <Card title={this.state.selectedProvider.id}>
                                    <Card.Meta
                                        avatar={<Avatar src={this.state.selectedProvider.profilePhoto} size={30} />}
                                        title={this.state.selectedProvider.name}
                                        description={this.state.selectedProvider.providerProfile && this.state.selectedProvider.providerProfile.description}
                                    />

                                </Card>
                            </div>
                            <div style={{ padding: 10 }}>
                                <div>
                                    服务项目
                                </div>
                                <Card title={this.state.selectedServiceItem.name}
                                >
                                    <div>
                                        {this.state.selectedServiceItem.description || "无描述信息..."}
                                    </div>
                                </Card>
                            </div>
                            <div style={{ padding: 10 }}>
                                <div>
                                    工作流变量
                                </div>
                                <Input.TextArea value={JSON.stringify(JSON.parse(this.state.wfVariables), null, 2)} disabled style={{ backgroundColor: "#FFF", color: "black", fontFamily: "monospace" }} />
                            </div>

                            <div style={{ padding: 10 }}>
                                <div>
                                    标签
                                </div>
                                <div>
                                    {this.state.tags.map(tag => <Tag color="blue">{tag}</Tag>)}
                                </div>
                            </div>

                        </Col>
                        <Col span={10}>
                            <div>
                                JSON 输出内容
                            </div>
                            <Input.TextArea rows={10} value={JSON.stringify(finalResult, null, 2)} disabled style={{ backgroundColor: "#FFF", color: "black", fontFamily: "monospace" }} />

                            <div style={{ marginTop: 20, textAlign: "center" }}>
                                <Button size={'large'} type="primary" onClick={() => {
                                    this._generateQrCode(finalResult)
                                }}>
                                    <Icon type="check-circle" />
                                    确认无误
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>)
        } else {
            return null
        }
    }

    renderResult() {
        if (this.state.step === 4) {
            return (
                <div>
                    <div style={{ padding: 20, textAlign: "center" }}>
                        <div style={{ fontSize: "200%" }}>生成结果</div>
                        <div style={{ marginTop: 20 }}>
                            <div style={{ fontSize: "150%", padding: 20 }}>{this.state.resultSceneId}</div>
                            <img style={{ width: 150 }} src={this.state.resultUrl} />
                        </div>
                    </div>
                </div>
            )
        } else {
            return null
        }
    }
    render() {
        return (<div>
            <Spin spinning={this.state.loading}>
                <Steps current={this.state.step} size="small">
                    <Steps.Step title="选择医生" />
                    <Steps.Step title="选择服务项目" />
                    <Steps.Step title="添加其他变量" />
                    <Steps.Step title="最后确认" />
                    <Steps.Step title="生成结果" />
                </Steps>
                <div style={{ marginTop: 20 }}>
                    {this.renderSelectProvider()}
                    {this.renderSelectServiceItem()}
                    {this.renderFillOtherVariables()}
                    {this.renderConfirm()}
                    {this.renderResult()}

                </div>
            </Spin>

        </div>)
    }
}