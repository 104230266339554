import './ServerSelect.css';
import { SERVER_INFO } from '../../util/server.js';
import React, { Component } from 'react';
import { Select } from 'antd';
const Option = Select.Option;

export default class ServerSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      server: ''
    };
  }

  componentWillMount() {
    const defaultServer = JSON.parse(
      localStorage.getItem('adminServer') || '{}'
    );
    this.setState({
      server: defaultServer
    });
  }

  render() {
    return (
      <Select
        labelInValue
        placeholder="请选择服务器"
        className="server-selection"
        value={this.state.server}
        onSelect={selectedValue => {
          localStorage.setItem('adminServer', JSON.stringify(selectedValue));
          this.setState({
            server: selectedValue
          });
        }}
      >
        {SERVER_INFO.map((item, index) => {
          return (
            <Option value={item.url} key={index}>
              {item.name}
            </Option>
          );
        })}
      </Select>
    );

    // return (
    //   <div className="server-select">
    //     <div className="server-select-title">选择服务器</div>
    //     <div className="server-select-label">
    //       目前选择的服务器:
    //       <Select
    //         labelInValue
    //         placeholder="请选择服务器"
    //         className="server-selection"
    //         value={this.state.server}
    //         onSelect={selectedValue => {
    //           localStorage.setItem(
    //             'adminServer',
    //             JSON.stringify(selectedValue)
    //           );
    //           this.setState({
    //             server: selectedValue
    //           });
    //         }}
    //       >
    //         {SERVER_INFO.map((item, index) => {
    //           return (
    //             <Option value={item.url} key={index}>
    //               {item.name}
    //             </Option>
    //           );
    //         })}
    //       </Select>
    //     </div>
    //   </div>
    // );
  }
}
