import React, { Component } from "react";
import "antd/dist/antd.css";

import "./KPI.css";
import { Form, Input, Button, DatePicker, message, Select } from "antd";
import { formActionLogs, serviceInstance, userTodo } from "../../util/Shufu";
import formConfig from "./FormConfig";

const { Option, OptGroup } = Select;

export default class ServiceItem extends Component {
  state = {
    kpiForm: {
      startTime: "",
      endTime: "",
      serviceItemId: "",
      formId: "",
    },
    kpiData: {
      formActionLogs: "",
      serviceInstance: "",
      userTodo: "",
    },
    formConfig: {},
    formList: [],
  };

  componentDidMount() {
    const adminServer =
      (JSON.parse(localStorage.getItem("adminServer")) || {}).label || "";
    let formList = formConfig.filter(
      (eachFormConfig) => adminServer.indexOf(eachFormConfig.server) >= 0
    );
    formList = formList.length === 0 ? formConfig : formList;
    this.setState({
      formList,
    });
  }

  // 转换数据（毫秒=>秒）
  changeSecondData(millisecondData) {
    return isNaN(millisecondData)
      ? "（无数据）"
      : (parseFloat(millisecondData) / 1000).toFixed(2) + "秒";
  }

  // 转换数据（毫秒=>秒）
  changeMinuteData(millisecondData) {
    return isNaN(millisecondData)
      ? "（无数据）"
      : (parseFloat(millisecondData) / 1000 / 60).toFixed(2) + "分";
  }


  async getFormActionLogs() {
    try {
      const result = await formActionLogs(this.state.kpiForm);
      this.setState({
        kpiData: {
          ...this.state.kpiData,
          formActionLogs: this.changeSecondData(result.javaApiResult),
        },
      });
    } catch (e) {
      console.log(e);
    }
  }

  async getServiceInstance() {
    try {
      const result = await serviceInstance(this.state.kpiForm);
      this.setState({
        kpiData: {
          ...this.state.kpiData,
          serviceInstance: this.changeMinuteData(result.javaApiResult),
        },
      });
    } catch (e) {
      console.log(e);
    }
  }

  async getUserTodo() {
    try {
      const result = await userTodo(this.state.kpiForm);
      this.setState({
        kpiData: {
          ...this.state.kpiData,
          userTodo: this.changeMinuteData(result.javaApiResult),
        },
      });
    } catch (e) {
      console.log(e);
    }
  }

  // 验证数据
  checkData() {
    const { startTime, endTime } = this.state.kpiForm;
    return startTime && endTime;
  }

  // 点击提交查询
  _handleSubmit() {
    if (this.checkData()) {
      this.getFormActionLogs();
      this.getServiceInstance();
      this.getUserTodo();
    } else {
      message.warning("请选择开始和结束日期！");
    }
  }

  handleChangeData = (e) => {
    this.setState({
      kpiForm: {
        ...this.state.kpiForm,
        [e.target.name]: e.target.value,
      },
    });
  };

  formOption() {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
      },
    };
  }

  // 修改开始时间格式并保存
  changeStartTime(date, dateString) {
    const startTime = dateString
      ? new Date(dateString + " 00:00:00").getTime()
      : "";
    this.setState({
      kpiForm: {
        ...this.state.kpiForm,
        startTime,
      },
    });
  }

  // 修改结束时间格式并保存
  changeEndTime(date, dateString) {
    const endTime = dateString
      ? new Date(dateString + " 23:59:59").getTime()
      : "";
    this.setState({
      kpiForm: {
        ...this.state.kpiForm,
        endTime,
      },
    });
  }

  // 切换FormId
  handleChangeFormId(formValue) {
    this.setState({
      kpiForm: {
        ...this.state.kpiForm,
        serviceItemId: formValue[0] || "",
        formId: formValue[1] || "",
      },
    });
  }

  render() {
    const { kpiForm, kpiData, formList } = this.state;
    return (
      <div className="contianer">
        <div>
          <Form {...this.formOption()}>
            <Form.Item label="开始时间">
              <DatePicker onChange={this.changeStartTime.bind(this)} />
            </Form.Item>
            <Form.Item label="结束时间">
              <DatePicker onChange={this.changeEndTime.bind(this)} />
            </Form.Item>
            <Form.Item label="量表">
              <Select onChange={this.handleChangeFormId.bind(this)}>
                {formList.map((formServer) => {
                  return (
                    <OptGroup label={formServer.productName}>
                      {formServer.forms.map((eachForm) => {
                        return (
                          <Option
                            value={[formServer.serviceItemId, eachForm.id]}
                          >
                            {eachForm.name}
                          </Option>
                        );
                      })}
                    </OptGroup>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item label="服务ID">
              <Input
                name="serviceItemId"
                value={kpiForm.serviceItemId}
                onChange={this.handleChangeData}
                placeholder="选择量表会自动填充服务ID"
              />
            </Form.Item>
            <Form.Item style={{ textAlign: "center" }}>
              <Button type="primary" onClick={this._handleSubmit.bind(this)}>
                查询
              </Button>
            </Form.Item>
          </Form>
        </div>
        <div>
          <Form {...this.formOption()}>
            <Form.Item label="每道题完成时间">
              {kpiData.formActionLogs}
            </Form.Item>
            <Form.Item label="每张表单完成时间">{kpiData.userTodo}</Form.Item>
            <Form.Item label="工作流完成时间">
              {kpiData.serviceInstance}
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  }
}
