const formConfig = [
  {
    productName: "精中写病历",
    server: "smhc",
    serviceItemId: "37b625a9-9c76-48d9-a1eb-3d557e54e48c",
    forms: [
      { id: "diagnosisAndTherapy_first", name: "填表人：本人或非本人" },
      {
        id: "diagnosisAndTherapy_basic",
        name: "基础信息如：姓名、身高、体重、联系方式等",
      },
      { id: "lisa.chooseSymptomsForHPI", name: "主要症状" },
      {
        id: "siuvo.confirmRelatedSymptomsTemplate",
        name: "具体症状：您还有以下具体症状吗？",
      },
      {
        id: "siuvo.confirmDdxSymptomsTemplate",
        name: "具体症状：请确认您是否有下列情况(请回答以下全部问题)",
      },
      {
        id: "psych_chiefComplaint_template",
        name: "主诉、现病史：症状起因、加重原因",
      },
      { id: "psych.hospitalHistory", name: "医院就诊历史" },
      {
        id: "diagnosisAndTherapy",
        name: "病史及个人史：如外伤、手术史、烟酒嗜好",
      },
      { id: "psych.familyHistory", name: "家族精神心理疾病史" },
    ],
  },
  {
    productName: "龙华写病历",
    server: "prod",
    serviceItemId: "e6320c9c-ba63-4779-84c8-9387297a261a",
    forms: [
      { id: "psych_longHuaBasicInfo", name: "基础信息" },
      {
        id: "ding.soap.chooseSymptoms_searchV3",
        name: "主诉",
      },
      { id: "psych_longHuaProcess", name: "自行服药详情" },
      {
        id: "psych.hospitalHistory_longhua",
        name: "医院就诊历史",
      },
      {
        id: "psych_longHuaGeneral",
        name: "起病以来的精神、睡眠、饮食、大小便情况",
      },
      {
        id: "psych_longHuaOtherHistory",
        name: "疾病史、家族遗传史、烟酒及过敏情况",
      },
    ],
  },
  {
    productName: "瑞金写病历",
    server: "prod",
    serviceItemId: "714eec73-25f4-4fa6-a33a-d1110e3394b6",
    forms: [
      { id: "psych_RuijinBasic", name: "基础信息" },
      {
        id: "psych_RuijinGeneral",
        name: "起病以来的精神、睡眠、饮食、大小便情况。过敏、疫苗、原体质情况",
      },
      {
        id: "psych_RuijinOtherHistory",
        name:
          "烟酒情况、输血史、手术外伤疫区接触情况，婚姻史、生育史、月经情况，疾病史、遗传病史",
      },
      { id: "siuvo.confirmRelatedSymptomsRuijing", name: "系统回顾" },
      { id: "ding.soap.chooseSymptoms_searchV2", name: "选择主要症状" },
      { id: "psych_RuijinProcess", name: "自行服药详情" },
      { id: "psych.hospitalHistory_ruijing", name: "医院就诊历史" },
    ],
  },
];

export default formConfig;
