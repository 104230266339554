import React, { Component } from "react";
import "antd/dist/antd.css";
import "./OrganizationManagement.css";
import { List, Icon, Row, Col, Input, Card, Button, Modal } from "antd";
import OrganizationForm from "../../component/OrganizationForm/OrganizationForm";
import moment from "moment";
import { message } from "antd/lib/index";
import { getOrganization } from "../../util/Shufu";

const Search = Input.Search;

export default class Organization extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentOrganization: {},
      organizationList: [],
      pageSize: 12,
      total: 1,
      searchKeyword: "",
      resultType: "latest",
      editOrganizationModalVisible: false
      //   orgInfor: []
    };
  }

  async componentDidMount() {
    await this._getLatestOrganization({
      offset: 0,
      limit: this.state.pageSize
    });
  }

  //根据关键字获取企业列表
  async _searchOrganization(params) {
    let { offset, limit, keyword } = params;
    try {
      let searchResult = [];
      let result = await getOrganization({ offset, limit });
      let orgInfor = result.javaApiResult;
      // console.log("result:", result);
      (orgInfor || []).map(org => {
        if (org.name.indexOf(keyword) >= 0) {
          searchResult.push(org);
        }
      });
      console.log("keyword: ", keyword, "\n", "searchResult: ", searchResult);
      this.setState({
        organizationList: searchResult,
        total: searchResult.length,
        resultType: "search"
      });
    } catch (e) {
      message.error(e.toString());
    }
  }

  //获取企业信息
  async _getLatestOrganization(params) {
    //设置企业数量
    let { offset, limit } = params;
    try {
      let result = await getOrganization({ offset, limit });
      // console.log("result类型: "+typeof(result));
      let orgInfor = result.javaApiResult;
      console.log("organization  information: ", result);
      // console.log("orgInfor: " , orgInfor);
      //更新企业链表、数量、搜索方式
      this.setState({
        organizationList: orgInfor,
        total: orgInfor.length,
        resultType: "latest"
      });
      console.log("organizationList: ", this.state.organizationList);
    } catch (e) {
      message.error(e.toString());
    }
  }

  //修改企业信息对话框
  renderEditOrganizationModal() {
    return (
      <Modal
        title="修改企业信息"
        // style = {{top: 20}}
        visible={this.state.editOrganizationModalVisible}
        width={"60%"}
        footer={null}
        // onOk = {this._handleSubmit}
        onCancel={() => this.setState({ editOrganizationModalVisible: false })}
      >
        <OrganizationForm
          id={this.state.currentOrganization.id}
          name={this.state.currentOrganization.name}
          phone={this.state.currentOrganization.phone}
          address={this.state.currentOrganization.address}
          website={this.state.currentOrganization.website}
          forms={this.state.currentOrganization.forms}
          description={this.state.currentOrganization.description}
          avatar={this.state.currentOrganization.avatar}
        />
      </Modal>
    );
  }

  render() {
    return (
      <div className="container">
        <Row>
          <Search
            placeholder="输入关键词"
            onSearch={value => {
              this.setState({
                searchKeyword: value
              });
              if (value !== "") {
                this._searchOrganization({
                  offset: 0,
                  limit: this.state.pageSize,
                  keyword: value
                });
              } else {
                this._getLatestOrganization({
                  offset: 0,
                  limit: this.state.pageSize
                });
              }
            }}
            enterButton
          />
        </Row>

        <Row>
          <Col span={24} style={{ marginTop: 15 }}>
            <List
              grid={{ gutter: 16, column: 4 }}
              dataSource={this.state.organizationList}
              pagination={{
                onChange: page => {
                  console.log(page);
                  if (this.state.resultType === "latest") {
                    this._getLatestOrganization({
                      offset: (page - 1) * this.state.pageSize,
                      limit: this.state.pageSize
                    });
                  } else if (this.state.resultType === "search") {
                    this._searchOrganization({
                      offset: (page - 1) * this.state.pageSize,
                      limit: this.state.pageSize,
                      keyword: this.state.searchKeyword
                    });
                  }
                },
                pageSize: this.state.pageSize,
                total: this.state.total
              }}
              renderItem={item => (
                <List.Item>
                  <Card
                    title={item.name || item.id}
                    actions={[
                      <Icon
                        type="setting"
                        onClick={() => {
                          console.log("item:", item);
                          this.setState({
                            editOrganizationModalVisible: true,
                            currentOrganization: item
                            // viewQrCode: item
                          });
                        }}
                      />
                    ]}
                  >
                    <div style={{ textAlign: "center" }}>
                      {/* <p>hello react</p> */}
                      <Row>{moment(item.createdTime).format("YYYY-MM-DD")}</Row>

                      <Row>{<img className="avatar" src={item.avatar} />}</Row>

                      {/*<WechatQrCode value={`http://www.siuvo.com.cn/download?data={"qrCodeId":"${item.id}}"`} />*/}
                    </div>
                  </Card>
                </List.Item>
              )}
            />
          </Col>
        </Row>
        {this.renderEditOrganizationModal()}
      </div>
    );
  }
}
