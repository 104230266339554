import React, {Component} from 'react';
// import './App.css';
import 'antd/dist/antd.css';

import './CopyServiceItem.css'
import {Row, Col, Table, Icon, Button, notification, Modal, Steps, Transfer, Input,Spin,List,Avatar,Tag,Progress} from 'antd';
import moment from 'moment'
import _ from 'lodash'

import {createServiceItem, getServiceItemsByUserId, getUser} from '../../util/Shufu';
import {message} from "antd/lib/index";
import {
    Link
} from "react-router-dom";

const Search = Input.Search
const confirm = Modal.confirm;
const Step = Steps.Step;
const ButtonGroup = Button.Group


export default class CopyServiceItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            statue: 0,
            status: "process", //wait process finish error

            availableServiceItemList: [],
            selectedServiceItemIdList: [],
            loading:false,
            targetUser:[],
            showIsUploadingModal:false,
            isUploading:false,
            currentUploadCount:0,
            currentUploadInfo:"",
            uploadResultList:[]

        };

    }

    componentDidMount() {

    }

    async searchServiceItemByUserId(userId) {
        if (!userId) {
            message.error('请输入用户id！');
            return
        }

        this.setState({
            loading:true
        })

        try{
            let result = await getServiceItemsByUserId(userId);
            let serviceItemList = result.javaApiResult;
            if (serviceItemList && serviceItemList.length) {
                console.log(serviceItemList)
                this.setState({availableServiceItemList: serviceItemList,selectedServiceItemIdList:[]})

            } else {
                message.error('没有找到服务项目。请确认输入的用户ID是正确的。');
            }
        }
        catch(e){
            message.error(e);

        }

        this.setState({
            loading:false
        })

    }


    renderSelectFrom() {
        if (this.state.statue === 0) {
            return (
                <div style={{
                    margin: 10,
                    padding: 20,
                    textAlign: "center",
                    borderTopColor: "#a0a0a0",
                    borderTopWidth: 1,
                    borderTopStyle: "solid"
                }}>
                    <div style={{marginBottom: 10, width: 870, marginLeft: "auto", marginRight: "auto"}}>
                        <Search
                            placeholder="你想克隆谁的服务项目？请输入用户ID"
                            onSearch={value => {
                                this.searchServiceItemByUserId(value)
                            }}
                            enterButton
                        />
                    </div>


                    <div style={{margin: "auto", textAlign: "center"}}>
                        <div style={{display: "inline-block"}}>
                            <Spin spinning={this.state.loading}>
                                <Transfer
                                    style={{textAlign: "left"}}
                                    rowKey={serviceItem => serviceItem.id}
                                    dataSource={this.state.availableServiceItemList}
                                    showSearch
                                    listStyle={{
                                        width: 400,
                                        height: 500,
                                    }}
                                    operations={['添加', '移除']}
                                    titles={['待选服务项目', <span style={{color:"#2593fc", fontWeight:"900"}}>已选服务项目</span>]}
                                    targetKeys={this.state.selectedServiceItemIdList}
                                    onChange={(targetKeys) => {
                                        this.setState({selectedServiceItemIdList: targetKeys});
                                    }}
                                    render={item => {
                                        return `${item.name} - ${item.description}`
                                    }}
                                    footer={this.renderFooter}
                                />
                            </Spin>
                        </div>
                    </div>

                    <div style={{marginTop: 10, width: 870,padding:20, marginLeft: "auto", marginRight: "auto"}}>
                        <Row type="flex" justify="space-around" align="middle">
                            <Col span={12}>
                                <div stlye={{textAlign:"center"}}>
                                    已选择{this.state.selectedServiceItemIdList.length}项服务项目
                                </div>
                            </Col>
                        </Row>
                        <Row type="flex" justify="space-around" align="middle">
                            <Col span={12} stlye={{textAlign:"center"}}>
                                <ButtonGroup>
                                    <Button type="primary"
                                            disabled
                                            onClick={()=>{
                                                this.setState({
                                                    statue:0
                                                })
                                            }}

                                    >
                                        <Icon type="left" />上一步
                                    </Button>
                                    <Button type="primary"
                                            disabled={this.state.selectedServiceItemIdList.length===0}
                                            onClick={()=>{
                                                this.setState({
                                                    statue:1
                                                })
                                            }}
                                    >
                                        下一步<Icon type="right" />
                                    </Button>
                                </ButtonGroup>
                            </Col>

                        </Row>
                    </div>

                </div>
            )

        } else {
            return null
        }
    }

    async addUser(userId){
        if (!userId) {
            message.error('请输入用户id！');
        }

        this.setState({
            loading:true
        })

        try{
            let result = await getUser(userId);
            let userInfo = result.javaApiResult;
            let targetUser = this.state.targetUser;
            for(let i = 0; i<targetUser.length;i++){
                if(targetUser[i].id===userInfo.id){
                    message.error(`${userInfo.id}已存在`);
                    this.setState({
                        loading:false
                    })
                    return
                }
            }
            targetUser.push(userInfo);
            this.setState({
                targetUser
            })
        }
        catch(e){
            message.error('添加用户错误。请确认用户ID是否正确');

        }

        this.setState({
            loading:false
        })
    }
    renderToUser(){
        if (this.state.statue === 1) {
            return(<div style={{
                margin: 10,
                padding: 20,
                textAlign: "center",
                borderTopColor: "#a0a0a0",
                borderTopWidth: 1,
                borderTopStyle: "solid"
            }}>
                <div style={{marginBottom: 10, width: 870, marginLeft: "auto", marginRight: "auto"}}>
                    <Search
                        placeholder="请输入用户ID"
                        onSearch={value => {
                            this.addUser(value)
                        }}
                        enterButton
                    />
                </div>
                <div style={{marginBottom: 10, width: 870, marginLeft: "auto", marginRight: "auto"}}>
                    <List
                        className="demo-loadmore-list"
                        itemLayout="horizontal"
                        grid={{ gutter: 16, column: 3 }}
                        dataSource={this.state.targetUser}
                        renderItem={(item,index) => {
                            return (
                                <List.Item
                                    style={{padding:5,backgroundColor:"white",borderRadius:5, borderColor:"#ffd400",borderWidth:1,borderStyle:"solid"}}
                                    actions={[<Button type={'danger'} shape={'circle'}  icon={'delete'}
                                    key={item.id}
                                    onClick={()=>{
                                        let targetUser = this.state.targetUser;
                                        targetUser.splice(index,1)
                                        this.setState({
                                            targetUser
                                        })
                                    }}
                                    />]}>
                                    <List.Item.Meta
                                        avatar={<Avatar src={item.profilePhoto} />}
                                        title={<a>{item.name}</a>}
                                    />
                                    {/*<div>{index}</div>*/}
                                </List.Item>
                            )
                        }}
                    />
                </div>
                <div style={{marginTop: 10, width: 870,padding:20, marginLeft: "auto", marginRight: "auto"}}>
                    <Row type="flex" justify="space-around" align="middle">
                        <Col span={12}>
                            <div stlye={{textAlign:"center"}}>
                                已选择{this.state.targetUser.length}名用户
                            </div>
                        </Col>
                    </Row>
                    <Row type="flex" justify="space-around" align="middle">
                        <Col span={12} stlye={{textAlign:"center"}}>
                            <ButtonGroup>
                                <Button type="primary"
                                        onClick={()=>{
                                            this.setState({
                                                statue:0
                                            })
                                        }}

                                >
                                    <Icon type="left" />上一步
                                </Button>
                                <Button type="primary"
                                        disabled={this.state.targetUser.length===0}
                                        onClick={()=>{
                                            this.setState({
                                                statue:2
                                            })
                                        }}
                                >
                                    下一步<Icon type="right" />
                                </Button>
                            </ButtonGroup>
                        </Col>

                    </Row>
                </div>
            </div>)
        }
        else{
            return null
        }
    }

    async createSingleServiceItem(serviceItem){


        this.setState({
            currentUploadInfo:`正在将 ${serviceItem.name} 克隆给 ${serviceItem.owner.name}...`
        })
        await createServiceItem(serviceItem)
    }
    async startClone(){

        this.setState({
            loading:true,
            currentUploadCount:0
        })

        let selectedServiceItemList = this.state.availableServiceItemList.filter((serviceItem)=>{
            let serviceItemId = serviceItem.id;
            return this.state.selectedServiceItemIdList.includes(serviceItemId)
        })
        let targetUser = this.state.targetUser;

        let newServiceItems = selectedServiceItemList.map((serviceItem)=>{
            return targetUser.map(user=>{
                return {
                    ...serviceItem,
                    id:undefined,
                    createdTime:undefined,
                    updatedTime:undefined,
                    qrCodeUrl:undefined,
                    sceneData:undefined,
                    owner:user,
                    provider:user,
                    organizationId: user.organizationIds[0]
                }
            })
        });
        newServiceItems = _.flatten(newServiceItems)

        console.log("startClone")
        console.log(selectedServiceItemList)
        console.log(targetUser)
        console.log(newServiceItems)

        for(let i = 0; i<newServiceItems.length;i++){
            this.setState({
                currentUploadCount:i
            })
            try{
                await this.createSingleServiceItem(newServiceItems[i])
                let uploadResultList = this.state.uploadResultList;
                uploadResultList.push({success:true, info:`${i}. 为 ${newServiceItems[i].owner.name} 创建 ${newServiceItems[i].name} 成功。`})
                this.setState({
                    uploadResultList
                })
            }
            catch(e){
                let uploadResultList = this.state.uploadResultList;
                uploadResultList.push({success:false, info:`${i}. 为 ${newServiceItems[i].owner.name} 创建 ${newServiceItems[i].name} 失败。${e}`})
                this.setState({
                    uploadResultList
                })
            }


        }
        this.setState({
            currentUploadInfo:`完成`,
            statue:3
        })
    }
    renderConfirm(){
        if (this.state.statue === 2) {
            let selectedServiceItemList = this.state.availableServiceItemList.filter((serviceItem)=>{
                let serviceItemId = serviceItem.id;
                return this.state.selectedServiceItemIdList.includes(serviceItemId)
            })
            let targetUser = this.state.targetUser;

            return(
                <div style={{
                    margin: 10,
                    padding: 20,
                    textAlign: "center",
                    borderTopColor: "#a0a0a0",
                    borderTopWidth: 1,
                    borderTopStyle: "solid"
                }}>
                    <Spin spinning={this.state.loading}>
                        <div className='title-text'>确认要将以下服务项目复制给以下用户</div>
                        <div>
                            <Row type="flex" justify="space-around" align="middle">
                                <Col span={11}>
                                    <List
                                        className="demo-loadmore-list"
                                        itemLayout="horizontal"
                                        grid={{ gutter: 16, column: 2 }}
                                        dataSource={selectedServiceItemList}
                                        renderItem={(item,index) => {
                                            return (
                                                <List.Item
                                                    style={{padding:10,borderRadius:5, borderColor:"#2593fc",backgroundColor:"white", borderWidth:1,borderStyle:"solid"}}
                                                >
                                                    <List.Item.Meta
                                                        avatar={<Icon type="reconciliation" theme="twoTone" style={{ fontSize: '25px'}}/>}
                                                        title={<a>{item.name}</a>}
                                                        description={item.description||"--"}
                                                    />


                                                </List.Item>
                                            )
                                        }}
                                    />
                                </Col>

                                <Col span={1}> <Icon type="right-square" theme="twoTone" style={{ fontSize: '25px'}}/> </Col>
                                <Col span={11}>
                                    <List
                                        className="demo-loadmore-list"
                                        itemLayout="horizontal"
                                        grid={{ gutter: 16, column: 2 }}
                                        dataSource={targetUser}
                                        renderItem={(item,index) => {
                                            return (
                                                <List.Item
                                                    style={{padding:5,backgroundColor:"white",borderRadius:5, borderColor:"#ffd400",borderWidth:1,borderStyle:"solid"}}
                                                >
                                                    <List.Item.Meta
                                                        avatar={<Avatar src={item.profilePhoto} />}
                                                        title={<a>{item.name}</a>}
                                                    />

                                                </List.Item>
                                            )
                                        }}
                                    />
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <div style={{marginTop: 10, width: 870,padding:20, marginLeft: "auto", marginRight: "auto"}}>
                                <Row type="flex" justify="space-around" align="middle">
                                    <Col span={12} stlye={{textAlign:"center"}}>
                                        <ButtonGroup>
                                            <Button type="primary"
                                                    onClick={()=>{
                                                        this.setState({
                                                            statue:1
                                                        })
                                                    }}

                                            >
                                                <Icon type="left" />上一步
                                            </Button>
                                            <Button type="primary"
                                                    disabled={this.state.selectedServiceItemIdList.length===0}
                                                    onClick={()=>{
                                                        this.startClone()
                                                    }}
                                            >
                                                确认克隆<Icon type="check-circle" />
                                            </Button>
                                        </ButtonGroup>
                                    </Col>

                                </Row>
                            </div>
                        </div>
                    </Spin>


                    {/*{this.renderUploadProcessModal()}*/}
                </div>
            )
        }else{
            return null
        }
    }



    renderResult(){
        if (this.state.statue === 3) {
            return(<div style={{
                margin: 10,
                padding: 20,
                textAlign: "center",
                borderTopColor: "#a0a0a0",
                borderTopWidth: 1,
                borderTopStyle: "solid"
            }}>
                <div className='title-text'>结果</div>
                <div style={{overflow: 'auto', height: '70vh', backgroundColor:"#f0f0f0",padding:10}}>
                    {this.state.uploadResultList.map((result)=>{
                        return result.success?(<div><Tag color="green">{result.info}</Tag></div>):(<div><Tag color="red">{result.info}</Tag></div>)
                    })
                    }

                </div>
            </div>)
        }else{
            return null
        }
    }
    render() {
        return (

            <div className='contianer'>
                <div className='title-text'>
                    克隆服务项目
                </div>
                <div>
                    <Steps current={this.state.statue} status={this.state.status}>
                        <Step title="选择服务项目"/>
                        <Step title="选择目标用户"/>
                        <Step title="确定"/>
                        <Step title="完成"/>
                    </Steps>
                </div>

                <div>
                    {this.renderSelectFrom()}
                    {this.renderToUser()}
                    {this.renderConfirm()}
                    {this.renderResult()}
                </div>


            </div>
        );
    }
}

