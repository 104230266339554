import React, { Component } from "react";
// import './App.css';
import "antd/dist/antd.css";

import "./UserManagement.css";
import {
  Input,
  Form,
  Icon,
  Button,
  Select,
  Modal,
  notification,
  Switch,
  Row,
  Col,
  List,
  Card,
  DatePicker,
  AutoComplete
} from "antd";
import moment from "moment";
import { Link } from "react-router-dom";
import {
  getUser,
  editUser,
  getServiceItemByProviderId
} from "../../util/Shufu";
import { message } from "antd/lib/index";
import queryString from "query-string";

const FormItem = Form.Item;
const Option = Select.Option;
const confirm = Modal.confirm;
const Search = Input.Search;
const { TextArea } = Input;
const dateFormat = "YYYY/MM/DD";
const AutoCompleteOption = AutoComplete.Option;

class UserManagementForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: null,
      modal1Visible: false,
      serviceItems: []
    };
    this._handleSubmit = this._handleSubmit.bind(this);
    this._getUser = this._getUser.bind(this);
  }

  async componentDidMount() {
    // this._getUser('drTing');
    let params = queryString.parse(this.props.location.search);
    console.log(params);
    window.scrollTo(0, 0);
    if (params.userId) {
      await this._getUser(params.userId);
    }
  }

  async _getUser(id) {
    let params = queryString.parse(this.props.location.search || "");
    params.userId = id;
    let qString = queryString.stringify(params);
    let path = this.props.history.location.pathname;
    this.props.history.push(path + "?" + qString);
    try {
      let result = await getUser(id);
      this.user = result.javaApiResult;
      this.setState({
        currentUser: result.javaApiResult
      });
      console.log(this.user);
      console.log(this.state.currentUser);
      this.props.form.setFieldsValue({
        nickname: this.user.nickname,
        name: this.user.name,
        cellphone: this.user.cellphone,
        languageSetting: this.user.languageSetting
      });
      if (this.user.userType === "provider") {
        const providerProfile = this.user.providerProfile || {};
        this.props.form.setFieldsValue({
          description: providerProfile.description
          // displayGroupServices: providerProfile.displayGroupServices || false,
          // enableBoard: providerProfile.enableBoard || false,
        });
      } //provider only field

      let serviceItemsResult = await getServiceItemByProviderId(id);

      this.setState({
        serviceItems: serviceItemsResult.javaApiResult
      });

      this.openNotification("获取数据成功");
    } catch (e) {
      message.error(e.toString());
    }
  }

  _handleSubmit() {
    const userFields = [
      "cellphone",
      "name",
      "gender",
      "birthday",
      "languageSetting",
      "nickname"
    ];
    // const providerFields = ['description', 'displayGroupServices', 'enableBoard']; //provider only fields
    const providerFields = [
      "email",
      "address",
      "description",
      "website",
      "title",
      "department",
      "description"
    ]; //provider only fields
    this.setState({
      modal1Visible: false
    });
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        console.log("Received values of form: ", values);
        let user = { id: this.state.currentUser.id };
        let changed = false;
        userFields.forEach(key => {
          if (
            values[key] !== undefined &&
            values[key] !== this.state.currentUser[key]
          ) {
            user[key] = values[key];
            changed = true;
          }
        });

        if (this.state.currentUser.userType === "provider") {
          let providerProfile = {};
          providerFields.forEach(key => {
            if (
              values[key] !== undefined &&
              values[key] !== this.state.currentUser.providerProfile[key]
            ) {
              providerProfile[key] = values[key];
              changed = true;
            }
          });
          user.providerProfile = providerProfile;
        }

        if (changed) {
          try {
            await editUser(user);
            this.openNotification("修改成功");
          } catch (e) {
            console.log(e);
            this.openBadNotifaction(e.toString());
          }
        } //check if user change anythin
      }
    });
  }

  openNotification(description) {
    notification.open({
      icon: <Icon type="smile" style={{ color: "#108ee9" }} />,
      message: "成功",
      description: description
    });
  }

  openBadNotifaction(description) {
    notification.open({
      icon: <Icon type="frown" style={{ color: "red" }} />,
      message: "失败",
      description: description
    });
  }

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue("password")) {
      callback("Two passwords that you enter is inconsistent!");
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(["confirm"], { force: true });
    }
    callback();
  };

  renderUser() {
    if (this.state.currentUser === null) {
      return null;
    }
    const currentUser = this.state.currentUser;
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 }
    };
    return (
      <div className="section">
        <Form
          key={currentUser.id}
          onSubmit={this._handleSubmit}
          className="login-form"
        >
          <FormItem {...formItemLayout} label="昵称">
            {getFieldDecorator("nickname", {
              rules: [{ required: true, message: "Please input nickname" }]
            })(
              <Input
                prefix={
                  <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
                }
                placeholder={currentUser.nickname}
              />
            )}
          </FormItem>
          <FormItem {...formItemLayout} label="姓名">
            {getFieldDecorator("name", {
              rules: [{ required: false, message: "Please input nickname" }]
            })(
              <Input
                prefix={
                  <Icon type="man" style={{ color: "rgba(0,0,0,.25)" }} />
                }
                placeholder={currentUser.name}
              />
            )}
          </FormItem>

          <FormItem {...formItemLayout} label="性别">
            {getFieldDecorator("gender", {
              rules: [{ required: false, message: "please choose gender" }]
            })(
              <Select initialValue="please select" style={{ width: "50%" }}>
                <Option value="male">男</Option>
                <Option value="female">女</Option>
              </Select>
            )}
          </FormItem>

          <FormItem {...formItemLayout} label="生日">
            {getFieldDecorator("birthday", {
              rules: [
                { required: false, message: "Please input your birthday!" }
              ]
            })(<DatePicker style={{ width: "50%" }} format={dateFormat} />)}
          </FormItem>

          <FormItem {...formItemLayout} label="重置密码" hasFeedback>
            {getFieldDecorator("password", {
              rules: [
                { required: false, message: "Please input your password!" },
                {
                  pattern: /^(?=.*?[0-9])(?=.*?[^\w\s]).{5,}$/,
                  message: "contain special characters and at least 5 words"
                },
                { validator: this.validateToNextPassword }
              ]
            })(<Input.Password />)}
          </FormItem>

          <FormItem {...formItemLayout} label="确认密码" hasFeedback>
            {getFieldDecorator("confirm", {
              rules: [
                { required: false, message: "Please confirm your password!" },
                { validator: this.compareToFirstPassword }
              ]
            })(<Input.Password onBlur={this.handleConfirmBlur} />)}
          </FormItem>

          <FormItem {...formItemLayout} label="手机号">
            {getFieldDecorator("cellphone", {
              rules: [{ required: false, message: "Please input nickname" }]
            })(
              <Input
                prefix={
                  <Icon type="phone" style={{ color: "rgba(0,0,0,.25)" }} />
                }
                placeholder={currentUser.cellphone}
              />
            )}
          </FormItem>
          <FormItem {...formItemLayout} label="语言">
            {getFieldDecorator("languageSetting", {
              rules: [
                { required: true, message: "Please input languageSetting" }
              ]
            })(
              <Select placeholder={currentUser.languageSetting}>
                <Option value="zh">zh</Option>
                <Option value="en">en</Option>
              </Select>
            )}
          </FormItem>

          <FormItem {...formItemLayout} label="公司">
            {getFieldDecorator("company", {
              rules: [{ required: false, message: "Please input your company" }]
            })(<Input type="text" />)}
          </FormItem>

          <FormItem {...formItemLayout} label="用户类型">
            {getFieldDecorator("userType", {
              rules: [{ required: true, message: "please choose userType" }]
            })(
              <Select initialValue="provider" style={{ width: "50%" }}>
                <Option value="provider">provider</Option>
              </Select>
            )}
          </FormItem>

          {this.renderProviderProfile()}
          <Button
            type="primary"
            onClick={() => this.setState({ modal1Visible: true })}
            className="login-form-button"
          >
            修改用户
          </Button>
        </Form>
      </div>
    );
  }

  renderProviderProfile() {
    const { getFieldDecorator } = this.props.form;
    const currentUser = this.state.currentUser;
    const providerProfile = currentUser.providerProfile || {};
    if (currentUser.userType !== "provider") {
      return null;
    }
    const formItemLayout = {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 }
    };
    const { autoCompleteResult } = this.state;
    const websiteOptions = (autoCompleteResult || []).map(website => (
      <AutoCompleteOption key={website}>{website}</AutoCompleteOption>
    ));
    return (
      <div>
        <p>以下为医生账户专用</p>
        <FormItem {...formItemLayout} label="邮箱">
          {getFieldDecorator("email", {
            rules: [
              { type: "email", message: "The input is not valid E-mail!" },
              { required: false, message: "Please input your E-mail!" }
            ]
          })(<Input type="text" />)}
        </FormItem>
        <FormItem {...formItemLayout} label="地址">
          {getFieldDecorator("address", {
            rules: [{ required: false, message: "Please input address!" }]
          })(<Input type="text" style={{ width: "100%" }} />)}
        </FormItem>
        <FormItem {...formItemLayout} label="网址">
          {getFieldDecorator("website", {
            rules: [{ required: false, message: "Please input website!" }]
          })(
            <AutoComplete
              dataSource={websiteOptions}
              onChange={this.handleWebsiteChange}
            >
              <Input />
            </AutoComplete>
          )}
        </FormItem>
        <FormItem {...formItemLayout} label="部门">
          {getFieldDecorator("department", {
            rules: [
              { required: false, message: "Please input your department" }
            ]
          })(<Input type="text" />)}
        </FormItem>
        <FormItem {...formItemLayout} label="职称">
          {getFieldDecorator("title", {
            rules: [{ required: false, message: "Please input title" }]
          })(<Input type="text" />)}
        </FormItem>

        <FormItem {...formItemLayout} label={"描述"}>
          {getFieldDecorator("description", {
            rules: [{ required: false, message: "Please input nickname" }]
          })(
            <TextArea
              rows={5}
              prefix={
                <Icon type="phone" style={{ color: "rgba(0,0,0,.25)" }} />
              }
              placeholder={providerProfile.description}
            />
          )}
        </FormItem>
        {/* <FormItem {...formItemLayout} label={'群体咨询'}>
                    {getFieldDecorator('displayGroupServices', {
                        rules: [{required: false, message: 'Please input languageSetting'}],
                    })(
                        <Switch defaultChecked={providerProfile.displayGroupServices || false}/>
                    )}</FormItem>
                <FormItem {...formItemLayout} label={'board功能'}>
                    {getFieldDecorator('enableBoard', {
                        rules: [{required: false, message: 'Please input languageSetting'}],
                    })(
                        <Switch defaultChecked={providerProfile.enableBoard || false}/>
                    )}</FormItem> */}
      </div>
    );
  }

  renderModal() {
    return (
      <Modal
        title="修改用户"
        style={{ top: 20 }}
        visible={this.state.modal1Visible}
        onOk={this._handleSubmit}
        onCancel={() => this.setState({ modal1Visible: false })}
      >
        <p>这将直接更改用户数据！！</p>
      </Modal>
    );
  }

  renderServiceItem(serviceItem) {
    return (
      <List.Item>
        <Card
          title={serviceItem.name}
          actions={[
            <Link to={`/ServiceItem?serviceItemId=${serviceItem.id}`}>
              <Icon type="setting" /> 设置
            </Link>
          ]}
        >
          <div>{serviceItem.description || "无描述信息..."}</div>
        </Card>
      </List.Item>
    );
  }

  renderServiceItems() {
    if (this.state.serviceItems && this.state.serviceItems.length) {
      return (
        <div className="section">
          <Row type="flex" justify="start">
            <Col span={10} className="title-text">
              <div>关联服务项目</div>
            </Col>
          </Row>

          <Row>
            <List
              grid={{ gutter: 16, column: 4 }}
              dataSource={this.state.serviceItems}
              renderItem={this.renderServiceItem}
            />
          </Row>
        </div>
      );
    }
    return null;
  }

  render() {
    return (
      <div className="contianer">
        <div style={{ marginBottom: 16 }}>
          <Search
            placeholder="请输入用户ID"
            enterButton="搜索"
            size="large"
            onSearch={value => this._getUser(value)}
          />
        </div>
        {this.renderUser()}

        {this.renderServiceItems()}
        {this.renderModal()}
      </div>
    );
  }
}

const UserManagement = Form.create()(UserManagementForm);

export default UserManagement;
