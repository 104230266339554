import React, { Component } from 'react';
import './App.css';
import 'antd/dist/antd.css';

import { Layout, Menu, Icon, Dropdown } from 'antd';

import { Route, HashRouter, Switch, Link } from 'react-router-dom';

import { Page1 } from './page/page1/Page1';
import { Page2 } from './page/page2/Page2';
import Login from './page/Login/Login';
import Homepage from './page/Homepage/Homepage';
import SiuvoDeposit from './page/SiuvoDeposit/SiuvoDeposit';
import UserManagement from './page/UserManagement/UserManagement';
import WebContent from './page/WebContent/WebContent';
import EditWebContent from './page/EditWebContent/EditWebContent';
import CreateWebContent from './page/CreateWebContent/CreateWebContent';
import BatchUploadWebContent from './page/BatchUploadWebContent/BatchUploadWebContent';
import CopyServiceItem from './page/CopyServiceItem/CopyServiceItem';
import Cookies from 'js-cookie';
import ServiceItem from './page/ServiceItem/ServiceItem';
import ServerIndicator from './component/ServerIndicator/ServerIndicator';
import ChuxinKPI from './page/ChuxinKPI/ChuxinKPI';
import QrCode from './page/QrCode/QrCode';
import WechatQrCode from './page/WechatQrCode/WechatQrCode';
import DeleteUser from './page/DeleteUser/DeleteUser';
import CreateUser from './page/CreateUser/CreateUser';
import CreateOrganization from './page/CreateOrganization/CreateOrganization';
import OrganizationManagement from './page/OrganizationManagement/OrganizationManagement';
import KPI from './page/KPI/KPI';
import ServerSelect from './page/Settings/ServerSelect';

// import {SiuvoDeposit} from
import { addWaterMark } from './tool/watermark';

const { Header, Sider, Content } = Layout;

const SubMenu = Menu.SubMenu;

class SiuvoAdminApp extends Component {
  state = {
    marginLeft: 200,
    collapsed: false,
    username: 'FOO'
  };
  componentDidMount() {
    addWaterMark();
    let username = Cookies.get('username');
    if (username) {
      this.setState({ username });
    }
  }

  onCollapse = collapsed => {
    console.log(collapsed);
    if (collapsed) {
      this.setState({
        marginLeft: 100
      });
    } else {
      this.setState({
        marginLeft: 200
      });
    }
    this.setState({ collapsed });
  };

  logOut() {
    console.log(window);
    Cookies.remove('SIUVO_SUPPORT_SESSION');
    Cookies.remove('username');
    window.location = '/#';
  }

  afterLogin(userInfo) {
    let { username } = userInfo;
    this.setState({ username });
  }

  render() {
    console.log(Cookies.get('SIUVO_SUPPORT_SESSION'));
    return (
      <div>
        <HashRouter>
          <Switch>
            <Route
              exact
              path="/"
              render={props => (
                <Login {...props} afterLogin={this.afterLogin.bind(this)} />
              )}
            />
            <Layout style={{ minHeight: '100vh' }}>
              <Sider
                collapsible
                collapsed={this.state.collapsed}
                onCollapse={this.onCollapse}
                style={{
                  overflow: 'auto',
                  height: '100vh',
                  position: 'fixed',
                  left: 0
                }}
              >
                <div className={this.state.collapsed ? 'logo-small' : 'logo'}>
                  <img
                    className={
                      this.state.collapsed ? 'logo-image-small' : 'logo-image'
                    }
                    src="http://www.siuvo.com.cn/S-small.png"
                  />
                </div>
                <Menu theme="dark" mode="inline">
                  {/* <SubMenu
                                    key="finance"
                                    title={<span><Icon type="money-collect" /><span>财务</span></span>}
                                >
                                    <Menu.Item key="siuvo_deposit">
                                        <Link to="/SiuvoDeposit">APP账户情况</Link>
                                    </Menu.Item>
                                </SubMenu>
                                <SubMenu
                                    key="stat"
                                    title={<span><Icon type="line-chart" /><span>统计信息</span></span>}
                                >
                                    <Menu.Item key="chuxin_kpi">
                                        <Link to="/ChuxinKPI">初心KPI</Link>
                                    </Menu.Item>
                                </SubMenu> */}
                  {/*<SubMenu*/}
                  {/*key="product"*/}
                  {/*title={<span><Icon type="shop" /><span>商品</span></span>}*/}
                  {/*>*/}
                  {/*<Menu.Item key="serviceInstanceToProduct">*/}
                  {/*<Link to="/">根据 Service Instance</Link>*/}
                  {/*</Menu.Item>*/}
                  {/*</SubMenu>*/}
                  {/* <SubMenu
                                    key="content"
                                    title={<span><Icon type="notification" /><span>新闻文本</span></span>}
                                >
                                    <Menu.Item key="webcontent">
                                        <Link to="/WebContent">查询新闻文本</Link>
                                    </Menu.Item>
                                    <Menu.Item key="CreateWebContent">
                                        <Link to="/CreateWebContent">添加新闻文本</Link>
                                    </Menu.Item>
                                    <Menu.Item key="BatchUploadWebContent">
                                        <Link to="/BatchUploadWebContent">批量上传</Link>
                                    </Menu.Item>
                                </SubMenu> */}
                  <SubMenu
                    key="user"
                    title={
                      <span>
                        <Icon type="user" />
                        <span>用户</span>
                      </span>
                    }
                  >
                    <Menu.Item key="create_user_account">
                      <Link to="/CreateUser">创建用户账户</Link>
                    </Menu.Item>
                    <Menu.Item key="init_doctor_account">
                      <Link to="/UserManagement">修改用户账户</Link>
                    </Menu.Item>
                    <Menu.Item key="delete_user">
                      <Link to="/DeleteUser">删除用户账户</Link>
                    </Menu.Item>
                  </SubMenu>

                  <SubMenu
                    key="service"
                    title={
                      <span>
                        <Icon type="build" />
                        <span>服务</span>
                      </span>
                    }
                  >
                    <Menu.Item key="101">
                      <Link to="/CopyServiceItem">克隆服务项目</Link>
                    </Menu.Item>
                    <Menu.Item key="qrcode">
                      <Link to="/QrCode">二维码管理</Link>
                    </Menu.Item>
                    <Menu.Item key="wechatcode">
                      <Link to="/WechatQrCode">微信小程序码管理</Link>
                    </Menu.Item>
                  </SubMenu>

                  <SubMenu
                    key="manage"
                    title={
                      <span>
                        <Icon type="home" />
                        <span>企业</span>
                      </span>
                    }
                  >
                    <Menu.Item key="CreateOrganization">
                      <Link to="/CreateOrganization">添加企业</Link>
                    </Menu.Item>
                    <Menu.Item key="editOrganization">
                      <Link to="/OrganizationManagement">查改企业信息</Link>
                    </Menu.Item>
                  </SubMenu>

                  <SubMenu
                    key="system"
                    title={
                      <span>
                        <Icon type="setting" />
                        <span>系统</span>
                      </span>
                    }
                  >
                    {/* <Menu.Item>
                      <Link to="/ServerSelect">选择服务器</Link>
                    </Menu.Item> */}
                    <Menu.Item key="KPI">
                      <Link to="/KPI">KPI</Link>
                    </Menu.Item>
                  </SubMenu>

                  {/*<SubMenu*/}
                  {/*key="system"*/}
                  {/*title={<span><Icon type="setting" /><span>系统</span></span>}*/}
                  {/*>*/}
                  {/*<Menu.Item key="logout" onClick={this.logOut}>                                        */}
                  {/*<Link to="/">退出</Link>*/}
                  {/*</Menu.Item>*/}
                  {/*</SubMenu>*/}
                </Menu>
              </Sider>
              <Layout style={{ marginLeft: this.state.marginLeft }}>
                {/*<Route exact path="/" component={Home}/>*/}
                <Header style={{ background: '#fff', padding: 0 }}>
                  <div style={{ textAlign: 'right', paddingRight: 20 }}>
                    <Dropdown
                      overlay={
                        <div>
                          <Menu>
                            <Menu.Item key="logout" onClick={this.logOut}>
                              <span>退出登陆</span>
                            </Menu.Item>
                            {/*<Menu.Item>*/}
                            {/*<a >2nd menu item</a>*/}
                            {/*</Menu.Item>*/}
                            {/*<Menu.Item>*/}
                            {/*<a >3rd menu item</a>*/}
                            {/*</Menu.Item>*/}
                          </Menu>
                        </div>
                      }
                    >
                      <a className="ant-dropdown-link">
                        Hello, {this.state.username} <Icon type="down" />
                      </a>
                    </Dropdown>
                  </div>
                </Header>
                <Content>
                  <Route exact path="/page1" component={Page1} />
                  <Route exact path="/page2" component={Page2} />
                  <Route exact path="/homepage" component={Homepage} />
                  <Route exact path="/SiuvoDeposit" component={SiuvoDeposit} />
                  <Route
                    exact
                    path="/UserManagement"
                    component={UserManagement}
                  />
                  <Route exact path="/ServiceItem" component={ServiceItem} />

                  <Route exact path="/WebContent" component={WebContent} />
                  <Route
                    exact
                    path="/EditWebContent/:id"
                    component={EditWebContent}
                  />
                  <Route
                    exact
                    path="/CreateWebContent"
                    component={CreateWebContent}
                  />
                  <Route
                    exact
                    path="/BatchUploadWebContent"
                    component={BatchUploadWebContent}
                  />
                  <Route
                    exact
                    path="/CopyServiceItem"
                    component={CopyServiceItem}
                  />
                  <Route exact path="/ChuxinKPI" component={ChuxinKPI} />
                  <Route exact path="/QrCode" component={QrCode} />
                  <Route exact path="/WechatQrCode" component={WechatQrCode} />
                  <Route exact path="/DeleteUser" component={DeleteUser} />
                  <Route exact path="/CreateUser" component={CreateUser} />
                  <Route
                    exact
                    path="/createOrganization"
                    component={CreateOrganization}
                  />
                  <Route
                    exact
                    path="/OrganizationManagement"
                    component={OrganizationManagement}
                  />
                  <Route
                    exact
                    path="/KPI"
                    component={KPI}
                  />
                  {/* <Route exact path="/ServerSelect" component={ServerSelect}/> */}
                </Content>
              </Layout>
            </Layout>
          </Switch>
        </HashRouter>
        <div
          style={{
            position: 'fixed',
            bottom: 10,
            right: 10,
            padding: 5,
            backgroundColor: '#011528',
            borderRadius: 3
          }}
        >
          <ServerIndicator />
        </div>
      </div>
    );
  }
}

export default SiuvoAdminApp;
