import React, { Component } from "react";
// import './App.css';
import "antd/dist/antd.css";

import { Layout, Menu, Breadcrumb, Icon } from "antd";

import { getMoney } from "../../util/Shufu";

const { Header, Content, Footer, Sider } = Layout;
const SubMenu = Menu.SubMenu;

export class Page1 extends React.Component {
  state = {
    collapsed: false
  };

  onCollapse = collapsed => {
    console.log(collapsed);
    this.setState({ collapsed });
  };

  async getMoney() {
    let result = await getMoney({ moneyId: "siuvo_deposit" });
    console.log("result", result);
  }

  render() {
    return (
      <div>
        {" "}
        PAGE 1<button onClick={this.getMoney}>test</button>
      </div>
    );
  }
}
