import React, { Component } from "react";
// import './App.css';
import "antd/dist/antd.css";
import { Icon, Button, Upload, message } from "antd";
import { getUploadImageUrl, uploadImage } from "../../util/Shufu";
import Cookies from "js-cookie";
import "./UploadAvatarForm.css";

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeUpload(file) {
  //限制图片格式
  const isImageFile =
    file.type === "image/jpeg" ||
    file.type === "image/png" ||
    file.type === "image/jpg" ||
    file.type === "image/bmp" ||
    file.type === "image/gif" ||
    file.type === "image/tiff";
  if (!isImageFile) {
    message.error("仅支持jpeg、png、jpg、bmp、gif、tiff格式的图片,请重传");
  }
  //限制图片大小
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("图片大小不能超过2M");
  }
  return isImageFile && isLt2M;
}

class UploadAvatarForm extends React.Component {
  state = {
    loading: false
  };

  handleChange = info => {
    if (info.file.status === "uploading") {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, imageUrl =>
        this.setState({
          imageUrl,
          loading: false
        })
      );

      if (this.props.onUploadFinish) {
        this.props.onUploadFinish(info.file.response);
      }
    }
  };

  render() {
    let siuvoSessionToken = Cookies.get("SIUVO_SUPPORT_SESSION");
    const uploadButton = (
      <div>
        <Icon type={this.state.loading ? "loading" : "plus"} />
        <div className="ant-upload-text">上传头像</div>
      </div>
    );
    const { imageUrl } = this.state;
    // console.log("imageUrl:",imageUrl);
    return (
      <div>
        <Upload
          name="image"
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          accept="image/*"
          // data={{ Authorization: siuvoSessionToken }}
          action={getUploadImageUrl()}
          headers={{ Authorization: siuvoSessionToken }}
          // withCredentials={{ Authorization: siuvoSessionToken }}
          // action={getUploadImageUrl}
          // beforeUpload={beforeUpload}
          onChange={this.handleChange}
        >
          {imageUrl ? (
            <img src={imageUrl} alt="avatar" width="100px" />
          ) : (
            uploadButton
          )}
        </Upload>
      </div>
    );
  }
}

export default UploadAvatarForm;
