import React, { Component } from "react";
// import './App.css';
import "antd/dist/antd.css";
import "./CreateOrganization.css";
import {
  Upload,
  Form,
  Icon,
  AutoComplete,
  Input,
  Button,
  Select,
  notification,
  Checkbox,
  Row,
  Col,
  Avatar
} from "antd";
import moment from "moment";
import { message } from "antd/lib";
import { createOrganization, getUploadImageUrl, login } from "../../util/Shufu";
import UploadAvatarForm from "../../component/UploadAvatarForm/UploadAvatarForm";

const FormItem = Form.Item;
const AutoCompleteOption = AutoComplete.Option;
const { TextArea } = Input;
const Option = Select.Option;

class CreateOrganizationForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      autoCompleteResult: [],
      buttonClicked: false,
      imageUrl: "",
      formData: { informWay: [{ id: "", tags: [] }] }
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  addFormData = index => {
    const { formData } = this.state;
    let da = { id: "", tags: [] };
    formData.informWay.push(da);
    this.setState({
      formData: formData
    });
  };

  delFormData = index => {
    const { formData } = this.state;
    formData.informWay.splice(index, 1);
    this.setState({ formData: formData });
  };

  removeConfigBtn(index, size) {
    if (size > 0) {
      return (
        <button
          style={{
            width: "25px",
            height: "25px",
            marginTop: "6px",
            padding: "0px"
          }}
          className="btn btn-small btn-info"
          onClick={() => this.delFormData(index)}
        >
          -
        </button>
      );
    }
  }

  handleWebsiteChange = value => {
    let autoCompleteResult;
    if (!value) {
      autoCompleteResult = [];
    } else {
      autoCompleteResult = [".com", ".org", ".net"].map(
        domain => `${value}${domain}`
      );
    }
    this.setState({ autoCompleteResult });
  };

  onChange(checkedValues) {
    console.log("checked = ", checkedValues);
  }

  handleSubmit = e => {
    e.preventDefault();
    const organizationField = [
      "id",
      "name",
      "phone",
      "address",
      "website",
      "description"
    ];
    // console.log("uploadValues: ",this.state.uploadValues);
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        // console.log("Received values of forms: ", values);
        let organizationBody = {
          forms: [],
          profilePhoto: this.state.imageUrl
        };
        // alert("avatar: ", this.state.imageUrl);
        organizationField.forEach(key => {
          organizationBody[key] = values[key];
        });
        console.log("formData before:", this.state.formData);
        this.state.formData &&
          this.state.formData.informWay.map((element, index) => {
            element.id = values["id" + index]
            element.tags = []
            if (values["tags" + index]) {
              const str = values["tags" + index].split(',')
              for (let tag in str) {
                element.tags.push(str[tag])
              }
            }
          });
        // console.log('informWay:',this.state.formData.informWay)
        organizationBody.forms = this.state.formData.informWay
        console.log("organization body:", organizationBody);
        try {
          await createOrganization(organizationBody);
          // alert("添加成功");
          notification.open({
            icon: <Icon type="smile" style={{ color: "#108ee9" }} />,
            message: "success",
            description: "添加成功"
          });
        } catch (e) {
          console.log(e);
          // alert("添加失败");
          notification.open({
            icon: <Icon type="frown" style={{ color: "red" }} />,
            message: "fail",
            description: "添加失败" + e
          });
        }
      }
    });
  };

  renderForms() {
    const { getFieldDecorator } = this.props.form;
    const { formData } = this.state;
    const data = formData.informWay;
    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 12 }
    };

    return (
      <div className="control-group">
        {data.map((element, index) => {
          return (
            <div key={index}>
              <Row style={{ paddingLeft: "19%" }}>
                <Col span={8}>
                  <FormItem {...formItemLayout} label="量表ID">
                    {getFieldDecorator(`id${index}`, {
                      rules: [{ required: false, message: "Please input formId" }]
                    })(
                      <Input key={element.id} />
                    )}
                  </FormItem>
                </Col>
                <Col span={9}>
                  <FormItem {...formItemLayout} label="量表标签">
                    {getFieldDecorator(`tags${index}`)(
                      <Input key={element.tags} />
                    )}
                  </FormItem>
                </Col>
                <span className="btn-group btn-small">
                  <button
                    style={{
                      width: "25px",
                      height: "25px",
                      marginTop: "6px",
                      padding: "0px"
                    }}
                    className="btn btn-primary"
                    onClick={() => this.addFormData(index)}
                  >
                    +
                  </button>
                  {this.removeConfigBtn(index, formData.informWay.length)}
                </span>
              </Row>
            </div>
          );
        })}
      </div>
    );
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 12 }
    };
    const tailFormItemLayout = {
      labelCol: { span: 10 },
      wrapperCol: { xs: { span: 6, offset: 0 }, sm: { span: 6, offset: 8 } }
    };
    const { autoCompleteResult } = this.state;
    const websiteOptions = autoCompleteResult.map(website => (
      <AutoCompleteOption key={website}>{website}</AutoCompleteOption>
    ));
    return (
      <div className="CreateOrganizationSection">
        <Form onSubmit={this.handleSubmit}>
          <UploadAvatarForm
            onUploadFinish={result => {
              this.setState({
                uploadValues: result,
                imageUrl: result.url
              });
            }}
          />
          <FormItem {...formItemLayout} label="企业ID">
            {getFieldDecorator("id", {
              rules: [{ required: true, message: "Please Organization ID" }]
            })(<Input type="text " />)}
          </FormItem>
          <FormItem {...formItemLayout} label="企业名称">
            {getFieldDecorator("name", {
              rules: [{ required: true, message: "Please input your name" }]
            })(<Input type="text " />)}
          </FormItem>

          <FormItem {...formItemLayout} label="联系方式">
            {getFieldDecorator("phone", {
              rules: [
                { required: false, message: "请输入1开头的11位手机号" },
                {
                  pattern: /^1(3|4|5|7|8)\d{9}$/,
                  message: "请输入1开头的11位手机号"
                }
              ]
            })(<Input />)}
          </FormItem>
          <FormItem {...formItemLayout} label="地址">
            {getFieldDecorator("address", {
              //   rules: [{ required: false, message: "Please input your address" }]
            })(<Input type="text" />)}
          </FormItem>
          <FormItem {...formItemLayout} label="网址">
            {getFieldDecorator("website", {
              //   rules: [{ required: false, message: "Please input website!" }]
            })(
              <AutoComplete
                dataSource={websiteOptions}
                onChange={this.handleWebsiteChange}
              >
                <Input />
              </AutoComplete>
            )}
          </FormItem>
          <FormItem {...formItemLayout} label={"描述"}>
            {getFieldDecorator("description", {
              rules: [{ required: false, message: "Please input nickname" }]
            })(
              <TextArea
                rows={5}
                prefix={
                  <Icon type="phone" style={{ color: "rgba(0,0,0,.25)" }} />
                }
              />
            )}
          </FormItem>

          {this.renderForms()}

          <FormItem {...tailFormItemLayout}>
            <div style={{ alignSelf: "center", textAlign: "center", flex: 1 }}>
              <Button type="primary" htmlType="submit">
                创建
              </Button>
            </div>
          </FormItem>
        </Form>
      </div>
    );
  }
}

const CreateOrganization = Form.create()(CreateOrganizationForm);
export default CreateOrganization;
