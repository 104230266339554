import React, { Component } from "react";
// import './App.css';
import "antd/dist/antd.css";

import {
  Row,
  Col,
  Table,
  Input,
  Form,
  Icon,
  Select,
  Switch,
  List,
  Card,
  Button,
  InputNumber
} from "antd";
import _ from "lodash";

import { getProduct } from "../../util/Shufu";
import { message } from "antd/lib/index";

const Search = Input.Search;
const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;

class _QrCodeForm extends Component {
  constructor(props) {
    super(props);

    // this.state = {
    //     id: props.id,
    //     title: props.title,
    //     data: JSON.stringify(JSON.parse(props.data || '{}'), null, 2)
    // }
  }

  async componentWillReceiveProps(newProps) {
    // this.setState({
    //     id: newProps.id,
    //     title: newProps.title,
    //     data: JSON.stringify(JSON.parse(newProps.data || '{}'), null, 2)
    // })

    if (JSON.stringify(newProps) !== JSON.stringify(this.props)) {
      this.props.form.setFieldsValue({
        id: newProps.id,
        title: newProps.title,
        data: JSON.stringify(JSON.parse(newProps.data || "{}"), null, 2)
      });
    }
  }

  async componentDidMount() {
    this.props.form.setFieldsValue({
      id: this.props.id,
      title: this.props.title,
      data: JSON.stringify(JSON.parse(this.props.data || "{}"), null, 2)
    });
  }

  async _handleSubmit() {
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        console.log("Received values of form: ", values);
        if (this.props.onSubmit) {
          this.props.onSubmit(values);
        }
        this.setState({
          loading: false
        });
      } else {
        this.setState({
          loading: false
        });
      }
    });
  }

  jsonValidatorThrottled = _.debounce(function(rule, value, callback) {
    try {
      JSON.parse(value);
      callback();
    } catch (e) {
      callback("错误的JSON格式: " + e.toString());
    }
  }, 1000);

  render() {
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: { span: 4 },
      wrapperCol: { span: 20 }
    };
    return (
      <div className="contianer">
        <div className="section">
          <Row>
            <Form
              key={this.props.id}
              onSubmit={this._handleSubmit}
              className="login-form"
            >
              <FormItem {...formItemLayout} label="二维码ID">
                {getFieldDecorator("id", {
                  rules: [{ required: false, message: "二维码ID" }]
                })(<Input disabled={true} />)}
              </FormItem>

              <FormItem {...formItemLayout} label="标题">
                {getFieldDecorator("title", {
                  rules: [{ required: false, message: "标题" }]
                })(<Input placeholder={"二维码标题"} />)}
              </FormItem>

              <FormItem {...formItemLayout} label="二维码内容">
                {getFieldDecorator("data", {
                  rules: [
                    { required: true, message: "请输入商品资源" },
                    { validator: this.jsonValidatorThrottled }
                  ]
                })(<TextArea rows={8} style={{ fontFamily: "monospace" }} />)}
              </FormItem>
            </Form>
          </Row>
        </div>

        <div style={{ textAlign: "center" }}>
          <Button
            type="primary"
            size={"large"}
            onClick={() => {
              this._handleSubmit();
            }}
            block
          >
            确定
          </Button>
        </div>
      </div>
    );
  }
}

const QrCodeForm = Form.create()(_QrCodeForm);

export default QrCodeForm;
