import React from 'react';
import 'antd/dist/antd.css';

import { login } from '../../util/Shufu';

import { Button, Form, Icon, Input, message, Spin } from 'antd';
import Cookies from 'js-cookie';
import { addWaterMark } from '../../tool/watermark';
import ServerSelect from '../Settings/ServerSelect.js';

const FormItem = Form.Item;
class Login extends React.Component {
  state = {
    loading: false
  };

  componentDidMount() {
    console.log(Cookies.get('SIUVO_SUPPORT_SESSION'));
    let siuvoSessionToken = Cookies.get('SIUVO_SUPPORT_SESSION');
    let username = Cookies.get('username');
    if (siuvoSessionToken && username) {
      if (
        this.props.afterLogin &&
        typeof this.props.afterLogin === 'function'
      ) {
        this.props.afterLogin({
          username
        });
      }
      this.props.history.push('/homepage');
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    this.setState({
      loading: true
    });
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        console.log('Received values of form: ', values);

        let { username, password } = values;

        try {
          let result = await login({ username, password });
          console.log(result);
          Cookies.set('SIUVO_SUPPORT_SESSION', result.token);
          Cookies.set('username', username);
          setTimeout(() => {
            if (
              this.props.afterLogin &&
              typeof this.props.afterLogin === 'function'
            ) {
              this.props.afterLogin({
                username
              });
            }
            this.props.history.push('/homepage');
            addWaterMark();
          }, 1000);
        } catch (e) {
          console.log('EEEEE', e);
          message.error(e.toString());
        }
        this.setState({
          loading: false
        });
      } else {
        this.setState({
          loading: false
        });
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <Spin spinning={this.state.loading}>
        <div
          style={{
            width: '500px',
            textAlign: 'center',
            margin: 'auto',
            padding: '30px',
            backgroundColor: '#f9f9f9',
            marginTop: '80px',
            borderRadius: '10px'
          }}
        >
          <div style={{ fontSize: '35px', marginBottom: '20px' }}>
            Siuvo Control Panel
          </div>
          <Form onSubmit={this.handleSubmit} className="login-form">
            <FormItem>
              {getFieldDecorator('username', {
                rules: [
                  { required: true, message: 'Please input your username!' }
                ]
              })(
                <Input
                  prefix={
                    <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />
                  }
                  placeholder="Username"
                />
              )}
            </FormItem>
            <FormItem>
              {getFieldDecorator('password', {
                rules: [
                  { required: true, message: 'Please input your Password!' }
                ]
              })(
                <Input
                  prefix={
                    <Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />
                  }
                  type="password"
                  placeholder="Password"
                />
              )}
            </FormItem>
            <FormItem>
              <ServerSelect></ServerSelect>
            </FormItem>
            <FormItem>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                Log in
              </Button>
            </FormItem>
          </Form>
        </div>
      </Spin>
    );
  }
}

export default Form.create()(Login);
