import React from 'react';
import 'antd/dist/antd.css';

import {getServiceInstanceWordCloud} from '../../util/Shufu';
import WordCloud from 'react-d3-cloud';




export default class Homepage extends React.Component {

    constructor(props){
        super(props);
        this.state={
            data:[]
        }
    }
    async componentDidMount() {
        // let siuvoSessionToken = Cookies.get('SIUVO_SUPPORT_SESSION');
        // if(siuvoSessionToken){
        //
        // }
        let result = await getServiceInstanceWordCloud();
        this.setState({
            data:[...result.result,{text:"SIUVO",value:50000}]
        })

    }

    fontSizeMapper(word){

        // return Math.log2(word.value + 2)*5
        return Math.min(word.value/4 + 10,130)


    };

    render() {
        return (
            <div style={{width:'90%',
                minHeight:"70vh",
                textAlign:"center",
                margin:'auto',
                // padding:"30px",
                backgroundColor:'white',
                marginTop:"30px",
                borderRadius:"10px",
                pointerEvents:"none",
                userSelect:"none",
                marginBottom:"30px"
            }}>
                <div style={{textAlign:"center", fontSize:30, padding:10,marginTop:-20}}>舒辅管理界面</div>


                <WordCloud
                    data={this.state.data}
                    fontSizeMapper={this.fontSizeMapper}
                    rotate={0}
                    font={"san-serif"}
                    padding={0}
                    width={1000}
                />
            </div>

        );
    }
}

