import React, { Component } from 'react';
// import './App.css';
import 'antd/dist/antd.css';

import './SiuvoDepost.css'
import {Row, Col, Table } from 'antd';
import moment from 'moment'

import {getMoney,getMoneyHistory} from '../../util/Shufu';
import {message} from "antd/lib/index";


const columns = [{
    title: 'DateTime',
    // dataIndex: 'transaction',
    sorter: false,
    render: history => {
        return moment(history.transaction.createdAt).format('lll')
    },
    // width: '20%',
}, {
    title: 'Type',
    // dataIndex: 'transaction',
    // filters: [
    //     { text: 'Male', value: 'male' },
    //     { text: 'Female', value: 'female' },
    // ],
    // width: '20%',
    render: history => {
        return history.transaction.transType
    },
}, {
    title: 'Amount',
    dataIndex: 'amountDelta',
    // filters: [
    //     { text: 'Male', value: 'male' },
    //     { text: 'Female', value: 'female' },
    // ],
    // width: '20%',
    align:"right",
    render: amount => {
        let amountFloat = parseFloat(amount);
        if(amountFloat<0){
            return(<span style={{color:'red'}}>{amount}</span>)
        }
        else if (amountFloat > 0){
            return (<span style={{color:'green'}}>{amount}</span>)
        }
        else{
            return <span style={{color:'grey'}}>{amount}</span>
        }

    },

},{
    title: 'Action',
    key: 'action',
    render: (text, history) => {
        // console.log(text,history);
        return (<span>
            See transaction detail
      {/*<Divider type="vertical" />*/}
      {/*<a href="javascript:;">Delete</a>*/}
    </span>
        )


    }


}];


export default class SiuvoDeposit extends Component {
    state = {
        collapsed: false,
        deposit:0,
        moneyHistory:[],
        pagination: {
            total:1000,
            pageSize:10
        },
        tableLoading:true
    };
    componentDidMount(){
        this._getMoneyHistory()
        this._getMoney()

    }


    async _getMoney(){
        try {
            let result = await getMoney({
                moneyId:"siuvo_deposit"

            })

            console.log(result)
            this.setState({
                deposit: parseFloat(result.amount)
            })
        }
        catch(e){
            message.error(e.toString());
            console.error(e)

        }
    }

    async _getMoneyHistory(offset,limit){
        this.setState({
            tableLoading:false
        })
        try {
            let result = await getMoneyHistory({
                moneyId:"siuvo_deposit",
                offset,
                limit

            })

            console.log(result)
            this.setState({
                moneyHistory:result.historyList
            })
        }
        catch(e){
            message.error(e.toString());
            console.error(e)
        }
        this.setState({
            tableLoading:false
        })
    }

    handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...this.state.pagination };
        pager.current = pagination.current;
        this.setState({
            pagination: pager,
        });
        console.log(pagination)
        this._getMoneyHistory(
            // results: pagination.pageSize,
            // page: pagination.current,
            // sortField: sorter.field,
            // sortOrder: sorter.order,
            //
        (pagination.current-1) * pagination.pageSize,

            pagination.pageSize,

        );
    }

    render() {
        return (

            <div className='contianer'>
                <div className='section'>
                    <Row type="flex" justify="start">
                        <Col span={10} className='title-text'>
                            <div>账户余额</div>
                        </Col>
                    </Row>
                    <Row type="flex" justify="start">
                        <Col span={10}>
                            <div className='deposit-value'>¥ {this.state.deposit.toFixed(2)}</div>
                        </Col>
                    </Row>
                </div>
                <div className='section'>
                    <Row type="flex" justify="start">
                        <Col span={10} className='title-text'>
                            <div>账户流水</div>
                        </Col>
                    </Row>
                    <Row type="flex" justify="start">
                        <div style={{width:"100%"}}>
                            <Table
                                columns={columns}
                                rowKey={moneyHistory => moneyHistory.moneyHistoryId}
                                dataSource={this.state.moneyHistory}
                                pagination={this.state.pagination}
                                loading={this.state.tableLoading}
                                onChange={this.handleTableChange}
                            />
                        </div>

                    </Row>
                </div>

            </div>
        );
    }
}

