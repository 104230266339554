import React, { Component } from 'react';
// import './App.css';
import './CreateUser.css';
import 'antd/dist/antd.css';
import {
  Row,
  Col,
  Form,
  Tooltip,
  Icon,
  DatePicker,
  AutoComplete,
  Input,
  Button,
  Select,
  Checkbox,
  Upload,
  notification,
  Spin
} from 'antd';
import moment from 'moment';
import { message } from 'antd/lib/index';
// import { request } from 'http';
// import request from '../../util/request';
import { addUser } from '../../util/Shufu';
import UploadAvatarForm from '../../component/UploadAvatarForm/UploadAvatarForm';
import { getOrganization } from '../../util/Shufu';
import debounce from 'lodash/debounce';

const dateFormat = "YYYY-MM-DD HH:mm:ss";
const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;
const AutoCompleteOption = AutoComplete.Option;

class CreateUserForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      autoCompleteResult: [],
      confirmDirty: false,
      userType: 'provider',
      imageUrl: '',
      organizationList: [],
      searching: false
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.searchOrganization = debounce(this.searchOrganization, 500).bind(this);
  }

  async searchOrganization(keyword) {
    // this.setState({ searching: true });
    try {
      let result = await getOrganization({ keyword });
      let organizationList = result.javaApiResult.filter(org => {
        return (org.name || '').toLowerCase().includes(keyword.toLowerCase()) || (org.id || '').toLowerCase().includes(keyword.toLowerCase());
      });
      this.setState({ organizationList });
    } catch (e) { }
    // this.setState({ searching: false });
  }

  handleSubmit = e => {
    e.preventDefault();
    const userField = [
      'company',
      'name',
      'cellphone',
      'birthday',
      'gender',
      'username',
      'password',
      'userType',
      'role',
      'organizationIds',
    ];
    const providerField = [

      'email',
      'address',
      'description',
      'website',
      'title',
      'department'
    ];
    // const {Form} = this.props;
    // this.props.form.setFieldValue();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        console.log('Received values of form: ', values);
        let providerProfile = {};
        let body = {
          organizationIds: [],
          id: values.username,
          profilePhoto: this.state.imageUrl,
          status: "ACTIVE"
        };
        userField.forEach(key => {
          body[key] = values[key];
        });
        if (this.state.userType === 'provider') {
          providerField.forEach(key => {
            providerProfile[key] = values[key];
          });
          body.providerProfile = providerProfile;
        }
        //body.providerProfile = providerProfile;
        console.log('body:', body);
        try {
          await addUser(body);
          this.successNotification('成功创建新用户！');
        } catch (e) {
          console.log(e);
          this.failNotification('创建新用户失败，' + e);
        }
      }
    });
  };

  successNotification(description) {
    notification.open({
      icon: <Icon type="smile" style={{ color: '#108ee9' }} />,
      message: 'success',
      description: description
    });
  }

  failNotification(description) {
    notification.open({
      icon: <Icon type="frown" style={{ color: 'red' }} />,
      message: '失败',
      description: description
    });
  }

  handleConfirmBlur = e => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  handleWebsiteChange = value => {
    let autoCompleteResult;
    if (!value) {
      autoCompleteResult = [];
    } else {
      autoCompleteResult = ['.com', '.org', '.net'].map(
        domain => `${value}${domain}`
      );
    }
    this.setState({ autoCompleteResult });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue('password')) {
      callback('Two passwords that you enter is inconsistent!');
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirm'], { force: true });
    }
    callback();
  };

  // validateToUsername = (rule, value, callback) => {
  //     const { form } = this.props;
  //     if (value) {
  //         form.validateFields(['username'], { force: true });
  //     }
  //     callback();
  //   };

  // compareToId = (rule,value,callback) => {
  //     const { form } = this.props;
  //     if(value && value !== form.getFieldValue('id')){
  //         callback('username should be same as id');
  //     }
  //     else {
  //         callback();
  //     };
  // }

  render() {
    // const {form} = this.props;
    const { getFieldDecorator } = this.props.form;
    // const orgId = [];
    let roles = ["Admin", "Doctor", "organizationFormAdmin", "reportApprover", "organizationAdmin"];
    // const {autoCompleteResult} = this.state;
    // const {Form:{username,password,company,profilePhoto,name,cellphone,birthday,gender,email,address,description,website,title,department,role,usertype}, onFormChange}=this.state;
    const formItemLayout = {
      labelCol: { span: 10 },
      wrapperCol: { span: 12 }
    };

    const tailFormItemLayout = {
      labelCol: { span: 24 },
      wrapperCol: { xs: { span: 24, offset: 0 }, sm: { span: 16, offset: 8 } }
    };

    return (
      <div className="CreateUserSection">
        {/* <header>创建用户账户</header> */}
        {/* <main> */}

        <UploadAvatarForm
          onUploadFinish={result => {
            this.setState({
              imageUrl: result.url
            });
          }}
        />

        <Form onSubmit={this.handleSubmit} style={{ width: '80%' }}>

          {/* {this.renderUploadImage()} */}

          <FormItem {...formItemLayout} label="用户名(仅限英语)">
            {getFieldDecorator('username', {
              rules: [
                { required: true, message: 'Please input your username' }
                // {validator: this.compareToId}
              ]
            })(<Input />)}
          </FormItem>

          <FormItem {...formItemLayout} label="姓名">
            {getFieldDecorator('name', {
              rules: [{ required: true, message: 'Please input your name' }]
            })(<Input type="text" />)}
          </FormItem>

          <FormItem {...formItemLayout} label="性别">
            {getFieldDecorator('gender', {
              rules: [{ required: true, message: 'please choose gender' }]
            })(
              <Select initialValue="please select" style={{ width: '50%' }}>
                <Option value="male">男</Option>
                <Option value="female">女</Option>
              </Select>
            )}
          </FormItem>

          <FormItem {...formItemLayout} label="生日">
            {getFieldDecorator('birthday', {
              rules: [
                { required: false, message: 'Please input your birthday!' }
              ]
            })(<DatePicker style={{ width: '50%' }} format={dateFormat} />)}
          </FormItem>

          <FormItem {...formItemLayout} label="密码" hasFeedback>
            {getFieldDecorator('password', {
              rules: [
                { required: true, message: 'Please input your password!' },
                {
                  pattern: /^(?=.*?[0-9])(?=.*?[^\w\s]).{5,}$/,
                  message: 'contain special characters and at least 5 words'
                },
                { validator: this.validateToNextPassword }
              ]
            })(<Input.Password />)}
          </FormItem>

          <FormItem {...formItemLayout} label="确认密码" hasFeedback>
            {getFieldDecorator('confirm', {
              rules: [
                { required: true, message: 'Please confirm your password!' },
                { validator: this.compareToFirstPassword }
              ]
            })(<Input.Password onBlur={this.handleConfirmBlur} />)}
          </FormItem>

          <FormItem {...formItemLayout} label="手机号">
            {getFieldDecorator('cellphone', {
              rules: [
                { required: true, message: 'Please input your cellphone!' },
                {
                  pattern: /^1(3|4|5|7|8)\d{9}$/,
                  message: 'Please input right cellphone'
                }
              ]
            })(<Input />)}
          </FormItem>

          <FormItem {...formItemLayout} label="公司">
            {getFieldDecorator('company', {
              rules: [{ required: false, message: 'Please input your company' }]
            })(<Input type="text" />)}
          </FormItem>

          <FormItem {...formItemLayout} label="机构" key="organizationIds">
            {getFieldDecorator('organizationIds', {
              rules: [{ required: false, message: 'Please input organizationIds!' }]
            })(
              <Select
                placeholder="请选择机构"
                mode="multiple"
                showSearch
                // onChange={this.handleSelectChange}
                loading={!this.state.organizationList}
                onSearch={this.searchOrganization}
                filterOption={false}
                notFoundContent={
                  this.state.searching ? <Spin size="small" /> : null
                }
              >
                {this.state.organizationList.map(org => {
                  return (
                    <Option value={org.id} key={org.id}>
                      {org.name}-{org.id}
                    </Option>
                  );
                })}
                {/* <Option value="male">male</Option>
              <Option value="female">female</Option> */}
              </Select>
            )}
          </FormItem>

          <FormItem {...formItemLayout} label="角色">
            {getFieldDecorator('role', {
              rules: [{ required: false, message: 'please choose role' }]
            })(
              <Select initialValue="please select" mode="multiple" style={{ width: '50%' }}>
                <Option value={roles[0]}>Admin</Option>
                <Option value={roles[1]}>Doctor</Option>
                <Option value={roles[2]}>organizationFormAdmin</Option>
                <Option value={roles[3]}>reportApprover</Option>
                <Option value={roles[4]}>organizationAdmin</Option>
              </Select>
            )}
          </FormItem>

          <FormItem {...formItemLayout} label="用户类型">
            {getFieldDecorator('userType', {
              rules: [{ required: true, message: 'please choose userType' }]
            })(
              <Select initialValue="provider" style={{ width: '50%' }}>
                <Option value="provider">provider</Option>
              </Select>
            )}
          </FormItem>

          {this.renderProviderProfile()}

          <FormItem {...tailFormItemLayout}>
            <div style={{ alignSelf: 'center', textAlign: 'center', flex: 1 }}>
              {getFieldDecorator('agreement', {
                valuePropName: 'checked'
              })(
                <Checkbox>
                  I have read the <a href="">agreement</a>
                </Checkbox>
              )}
            </div>
          </FormItem>

          <FormItem {...tailFormItemLayout}>
            <div style={{ alignSelf: 'center', textAlign: 'center', flex: 1 }}>
              <Button type="primary" htmlType="submit">
                Create
              </Button>
            </div>
          </FormItem>
        </Form>
      </div>
    );
  }

  renderProviderProfile() {
    const { getFieldDecorator } = this.props.form;
    if (this.state.userType !== 'provider') {
      return null;
    }
    const { autoCompleteResult } = this.state;
    const websiteOptions = autoCompleteResult.map(website => (
      <AutoCompleteOption key={website}>{website}</AutoCompleteOption>
    ));
    const formItemLayout = {
      labelCol: { span: 10 },
      wrapperCol: { span: 12 }
    };
    return (
      <div className="profileProviderSection">
        <Row style={{ marginLeft: '28%' }}>
          <p>以下为医生账户专用</p>
        </Row>

        <FormItem {...formItemLayout} label="邮箱">
          {getFieldDecorator('email', {
            rules: [
              { type: 'email', message: 'The input is not valid E-mail!' },
              { required: false, message: 'Please input your E-mail!' }
            ]
          })(<Input type="text" />)}
        </FormItem>
        <FormItem {...formItemLayout} label="地址">
          {getFieldDecorator('address', {
            rules: [{ required: false, message: 'Please input address!' }]
          })(<Input type="text" style={{ width: '100%' }} />)}
        </FormItem>

        <FormItem {...formItemLayout} label="网址">
          {getFieldDecorator('website', {
            rules: [{ required: false, message: 'Please input website!' }]
          })(
            <AutoComplete
              dataSource={websiteOptions}
              onChange={this.handleWebsiteChange}
            >
              <Input />
            </AutoComplete>
          )}
        </FormItem>
        <FormItem {...formItemLayout} label="部门">
          {getFieldDecorator('department', {
            rules: [
              { required: false, message: 'Please input your department' }
            ]
          })(<Input type="text" />)}
        </FormItem>
        <FormItem {...formItemLayout} label="职称">
          {getFieldDecorator('title', {
            rules: [{ required: false, message: 'Please input title' }]
          })(<Input type="text" />)}
        </FormItem>
        <FormItem {...formItemLayout} label="描述">
          {getFieldDecorator('description', {
            rules: [
              { required: false, message: 'Please input your description' }
            ]
          })(<TextArea rows={4} />)}
        </FormItem>
      </div>
    );
  }

  // renderUploadImage() {
  //   const { getFieldDecorator } = this.props.form;
  //   const formItemLayout = {
  //     labelCol: { span: 10 },
  //     wrapperCol: { span: 12 }
  //   };
  //   return (
  //     <div>
  //       <FormItem {...formItemLayout} label="头像">
  //         <Row type="flex" style={{ height: "1px" }}>
  //           <Col span={18}>
  //             <div>
  //               {getFieldDecorator("profilePhoto", {
  //                 rules: [
  //                   { required: false, message: "please input image url" }
  //                 ]
  //               })(<Input value={this.state.imageUrl} />)}
  //             </div>
  //           </Col>
  //           <Col span={6}>
  // <UploadImageForm
  //   onUploadFinish={result => {
  //     this.props.form.setFieldsValue({
  //       profilePhoto: result.url
  //     });
  //     this.setState({
  //       imageUrl: result.url
  //     });
  //   }}
  // />
  //           </Col>
  //         </Row>
  //       </FormItem>
  //     </div>
  //   );
  // }
}
const CreateUser = Form.create()(CreateUserForm);
export default CreateUser;
