import React, { Component } from "react";
import "./WechatQrCode.css";
import "antd/dist/antd.css";

import {
  Layout,
  Menu,
  List,
  Icon,
  Row,
  Col,
  Input,
  Card,
  Button,
  Modal
} from "antd";
import {
  getLatestWechatQrCode,
  searchQrCode,
  createQrCode
} from "../../util/Shufu";
import WechatQrCodeForm from "../../component/WechatQrCodeForm/WechatQrCodeForm";
import CreateWechatQrcodeForm from "../../component/CreateWechatQrcodeForm/CreateWechatQrcodeForm";

import moment from "moment";
import { Form, message } from "antd/lib/index";

const { Header, Content, Footer, Sider } = Layout;
const SubMenu = Menu.SubMenu;
const Search = Input.Search;

export default class WechatQrCode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      qrCodeDataList: [],
      total: 1,
      pageSize: 12,
      resultType: "latest",
      searchKeyword: "",
      createQrCodeModalVisible: false,
      editQrCodeModalVisible: false,
      viewQrCode: {}
    };
  }

  async componentDidMount() {
    await this._getLatestWechatQrCodes({
      offset: 0,
      limit: this.state.pageSize
    });
  }

  async _getLatestWechatQrCodes(options) {
    let { offset, limit } = options;
    let result = await getLatestWechatQrCode({ offset, limit });
    let { total, data } = result;
    this.setState({
      qrCodeDataList: data,
      total,
      resultType: "latest"
    });
  }

  async _searchQrCodes(options) {
    let { offset, limit, keyword } = options;
    let result = await searchQrCode({ offset, limit, keyword });
    let { total, data } = result;
    this.setState({
      qrCodeDataList: data,
      total,
      resultType: "search"
    });
  }

  renderCreateQrCodeModal() {
    return (
      <div>
        <Modal
          title={"新建二维码"}
          style={{ top: 20 }}
          visible={this.state.createQrCodeModalVisible}
          // onOk={this.handleOk}
          // onCancel={this.handleCancel}
          width={"80%"}
          footer={[]}
          onCancel={() => {
            this.setState({ createQrCodeModalVisible: false });
          }}
        >
          <CreateWechatQrcodeForm />
        </Modal>
      </div>
    );
  }

  async _createNewQrCode(data) {
    console.log("111111", data);
    try {
      let result = await createQrCode(data);
      message.success("创建二维码成功");
    } catch (e) {
      message.error(e.toString());
    }
    this.setState({
      createQrCodeModalVisible: false
    });
    this._getLatestWechatQrCodes({
      offset: 0,
      limit: this.state.pageSize
    });
  }

  renderEditQrCodeModal() {
    return (
      <div>
        <Modal
          title={`查看二维码 - ${this.state.viewQrCode.sceneId || ""}`}
          style={{ top: 20 }}
          visible={this.state.editQrCodeModalVisible}
          // onOk={this.handleOk}
          // onCancel={this.handleCancel}
          width={"80%"}
          footer={[]}
          onCancel={() => {
            this.setState({ editQrCodeModalVisible: false });
          }}
        >
          <Row>
            <Col span={8}>
              <div
                style={{
                  fontSize: "150%",
                  textAlign: "center",
                  marginBottom: 40
                }}
              >
                {this.state.viewQrCode.title || "生成二维码"}
              </div>
              <div style={{ textAlign: "center" }}>
                <div>
                  <img
                    className={"qrcode-pic"}
                    src={this.state.viewQrCode.imgUrl}
                  />
                </div>{" "}
              </div>
            </Col>
            <Col span={16}>
              <WechatQrCodeForm
                id={this.state.viewQrCode.id}
                sceneId={this.state.viewQrCode.sceneId}
                sceneData={this.state.viewQrCode.sceneData}
              />
            </Col>
          </Row>
        </Modal>
      </div>
    );
  }

  render() {
    return (
      <div>
        <div className="contianer">
          <Row>
            <Col span={20}>
              <Search
                disabled={true}
                placeholder="输入关键词"
                onSearch={value => {
                  this.setState({
                    searchKeyword: value
                  });
                  if (value !== "") {
                    this._searchQrCodes({
                      offset: 0,
                      limit: this.state.pageSize,
                      keyword: value
                    });
                  } else {
                    this._getLatestWechatQrCodes({
                      offset: 0,
                      limit: this.state.pageSize
                    });
                  }
                }}
                enterButton
              />
            </Col>

            <Col span={4}>
              <div style={{ textAlign: "right" }}>
                <Button
                  type="primary"
                  icon="plus-circle"
                  onClick={() => {
                    this.setState({ createQrCodeModalVisible: true });
                  }}
                >
                  新建二维码
                </Button>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <div style={{ marginTop: 15 }}>
                <List
                  grid={{ gutter: 16, column: 4 }}
                  dataSource={this.state.qrCodeDataList}
                  pagination={{
                    onChange: page => {
                      console.log(page);
                      if (this.state.resultType === "latest") {
                        this._getLatestWechatQrCodes({
                          offset: (page - 1) * this.state.pageSize,
                          limit: this.state.pageSize
                        });
                      } else if (this.state.resultType === "search") {
                        this._searchQrCodes({
                          offset: (page - 1) * this.state.pageSize,
                          limit: this.state.pageSize,
                          keyword: this.state.searchKeyword
                        });
                      }
                    },
                    pageSize: this.state.pageSize,
                    total: this.state.total
                  }}
                  renderItem={item => (
                    <List.Item>
                      <Card
                        title={item.title || item.sceneId}
                        actions={[
                          <Icon
                            type="setting"
                            onClick={() => {
                              console.log(item);
                              this.setState({
                                editQrCodeModalVisible: true,
                                viewQrCode: item
                              });
                            }}
                          />
                        ]}
                      >
                        <div style={{ textAlign: "center" }}>
                          <div>
                            {moment(item.createdTime).format("YYYY-MM-DD")}
                          </div>
                          <div>
                            <img className={"qrcode-pic"} src={item.imgUrl} />
                          </div>
                          {/*<WechatQrCode value={`http://www.siuvo.com.cn/download?data={"qrCodeId":"${item.id}}"`} />*/}
                        </div>
                      </Card>
                    </List.Item>
                  )}
                />
              </div>
            </Col>
          </Row>
        </div>

        {this.renderCreateQrCodeModal()}
        {this.renderEditQrCodeModal()}
      </div>
    );
  }
}
